import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate, Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../components/context/AuthContext';
import { useAuth } from '../components/context/AuthContext';
import Sidebar from '../components/sidebar';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const ProtectedLayout = () => {
  const { auth, logout } = useAuth();
  const { updateUser } = useContext(AuthContext);
  const navigate = useNavigate();
  
  // Estados
  const [isVerifying, setIsVerifying] = useState(true);
  const [loadingCampaigns, setLoadingCampaigns] = useState({});
  const [metaCampaigns, setMetaCampaigns] = useState({});
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [googleCampaigns, setGoogleCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isNewCampaignModalOpen, setIsNewCampaignModalOpen] = useState(false);

  // Función de logout
  const handleLogout = useCallback(() => {
    try {
      logout();
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  }, [logout, navigate]);

  // Validación de token
  useEffect(() => {
    const validateToken = async () => {
      if (!auth.token) {
        setIsVerifying(false);
        navigate('/login');
        return;
      }

      try {
        const response = await axios.get(`${API_URL}/api/validate-token`, {
          headers: { Authorization: `Bearer ${auth.token}` }
        });

        if (!response.data.valid) {
          throw new Error('Token invalid');
        }

        setIsVerifying(false);
      } catch (error) {
        console.error('Token validation failed:', error);
        logout();
        navigate('/login', { 
          state: { error: 'Tu sesión ha expirado. Por favor, inicia sesión nuevamente.' }
        });
      }
    };

    validateToken();
  }, [auth.token, logout, navigate]);

  // Fetch de campañas
  const fetchCampaigns = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/campaigns`, {
        headers: { Authorization: `Bearer ${auth.token}` }
      });
      setCampaigns(response.data.campaigns || []);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      if (error.response?.status === 401) {
        handleLogout();
      }
    }
  }, [auth.token, handleLogout]);

  useEffect(() => {
    if (!isVerifying) {
      fetchCampaigns();
    }
  }, [isVerifying, fetchCampaigns]);

  const fetchMetaCampaigns = useCallback(async (accountId) => {
    if (loadingCampaigns[accountId]) return;
    
    setLoadingCampaigns(prev => ({ ...prev, [accountId]: true }));
    try {
      const response = await axios.get(
        `${API_URL}/api/meta/campaigns/${accountId}`,
        {
          headers: { Authorization: `Bearer ${auth.token}` }
        }
      );
      setMetaCampaigns(prev => ({
        ...prev,
        [accountId]: response.data
      }));
    } catch (error) {
      setError(error.response?.data?.error || 'Error al obtener campañas de Meta');
    } finally {
      setLoadingCampaigns(prev => ({ ...prev, [accountId]: false }));
    }
  }, [auth.token, loadingCampaigns]);

  const fetchGoogleCampaigns = useCallback(async (customerId) => {
    if (loadingCampaigns[customerId]) return;
    
    setLoadingCampaigns(prev => ({ ...prev, [customerId]: true }));
    try {
      const response = await axios.get(
        `${API_URL}/google_ads/campaigns/${customerId}`,
        {
          headers: { Authorization: `Bearer ${auth.token}` }
        }
      );

      const formattedCampaigns = (response.data || []).map(campaign => ({
        campaign_id: campaign.campaign_id,
        name: campaign.name,
        status: campaign.status,
        advertising_channel_type: campaign.advertising_channel_type,
        budget_amount_micros: campaign.budget_amount_micros,
        metrics: campaign.metrics || {
          impressions: 0,
          clicks: 0,
          cost: 0,
          conversions: 0,
          conversion_value: 0
        },
        start_date: campaign.start_date,
        end_date: campaign.end_date
      }));

      setGoogleCampaigns(prev => ({
        ...prev,
        [customerId]: formattedCampaigns
      }));
    } catch (error) {
      console.error('Error fetching Google campaigns:', error);
      if (error.response?.status === 401) {
        setError('La sesión de Google Ads ha expirado. Por favor, vuelva a conectar su cuenta.');
        updateUser({
          ...auth.user,
          google_connected: false,
          google_customer_id: null
        });
      } else {
        setError('Error al obtener campañas de Google Ads. Por favor, intente de nuevo.');
      }
    } finally {
      setLoadingCampaigns(prev => ({ ...prev, [customerId]: false }));
    }
  }, [auth.token, auth.user, updateUser, loadingCampaigns]);

  if (isVerifying) {
    return (
      <div className="flex min-h-screen items-center justify-center bg-gray-50">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto mb-4"></div>
          <p className="text-gray-600">Verificando autenticación...</p>
        </div>
      </div>
    );
  }

  if (!auth.token) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="flex h-screen">
       <Sidebar 
      campaigns={campaigns}
      selectedCampaign={selectedCampaign}
      setSelectedCampaign={setSelectedCampaign}
      setIsNewCampaignModalOpen={setIsNewCampaignModalOpen}
      handleLogout={handleLogout}         // Pasando la función correctamente
      onExpandedChange={setSidebarExpanded}
    />
      <main className={`flex-1 transition-all duration-300 ease-in-out
        ${sidebarExpanded ? 'lg:pl-64' : 'lg:pl-14'}`}>
        <Outlet context={{ 
          campaigns,
          setCampaigns,
          fetchCampaigns,
          isNewCampaignModalOpen,
          setIsNewCampaignModalOpen
        }}/>
      </main>
    </div>
  );
};

export default ProtectedLayout;