import React, { useState, useEffect } from 'react';
import {
  User, Mail, Lock, Camera, Shield, CreditCard, CheckCircle, Building2,
  Crown, Star, Clock, BarChart3, AlertCircle, ChevronRight, Gauge,
  Users, Settings, Bell, Globe, Save, X, Trash2
} from 'lucide-react';
import axios from 'axios';
import './ProfilePage.css';
import { useAuth } from '../../components/context/AuthContext';
import SubscriptionPlans from '../../components/OnboardingModal';

const INDUSTRIES = [
  'Tecnología', 'Retail', 'Servicios', 'Manufactura', 'Salud', 'Educación',
  'Finanzas', 'Otros'
];

const ProfilePage = () => {
  const { auth, updateUser } = useAuth();
  const [activeTab, setActiveTab] = useState('overview');
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    industry: '',
    websiteUrl: '',
    theme: 'dark',
    notifications: {
      email: true,
      marketing: false,
      push: true
    },
    security: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    language: 'es'
  });

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Authorization': `Bearer ${auth?.token}`,
      'Content-Type': 'application/json'
    }
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.token]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [userData, subscriptionData] = await Promise.all([
        axiosInstance.get('/api/user'),
        axiosInstance.get('/api/subscriptions/status')
      ]);

      setFormData(prev => ({
        ...prev,
        firstName: userData.data.first_name || '',
        lastName: userData.data.last_name || '',
        email: userData.data.email || '',
        companyName: userData.data.business_name || '',
        industry: userData.data.industry || '',
        websiteUrl: userData.data.website_url || '',
        theme: userData.data.theme || 'dark',
        language: userData.data.language || 'es',
        notifications: userData.data.notifications || {
          email: true,
          marketing: false,
          push: true
        }
      }));

      setSubscriptionData(subscriptionData.data.subscription);
    } catch (error) {
      setError('Error al cargar los datos. Por favor, intenta de nuevo.');
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axiosInstance.put('/api/user', formData);
      setNotification({
        type: 'success',
        message: 'Perfil actualizado exitosamente'
      });
      await fetchData();
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Error al actualizar el perfil'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const uploadData = new FormData();
    uploadData.append('profileImage', file);

    try {
      setLoading(true);
      await axiosInstance.post('/api/user/profile-image', uploadData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      await fetchData();
      setNotification({
        type: 'success',
        message: 'Imagen actualizada exitosamente'
      });
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Error al actualizar la imagen'
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = async () => {
    const { newPassword, confirmPassword, currentPassword } = formData.security;
    if (newPassword !== confirmPassword) {
      setNotification({
        type: 'error',
        message: 'Las contraseñas no coinciden'
      });
      return;
    }

    try {
      setLoading(true);
      await axiosInstance.post('/api/user/change-password', {
        currentPassword,
        newPassword
      });
      setNotification({
        type: 'success',
        message: 'Contraseña actualizada exitosamente'
      });
      setShowPasswordDialog(false);
      setFormData(prev => ({
        ...prev,
        security: { currentPassword: '', newPassword: '', confirmPassword: '' }
      }));
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Error al cambiar la contraseña'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      setLoading(true);
      await axiosInstance.delete('/api/user');
      // Redirigir al usuario a la página de login
      window.location.href = '/login';
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Error al eliminar la cuenta'
      });
    } finally {
      setLoading(false);
      setShowDeleteDialog(false);
    }
  };

  if (loading && !formData.email) {
    return (
      <div className="d-flex justify-content-center align-items-center min-vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Cargando...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="profile-container">
      <div className="container">
        {/* Header */}
        <div className="row mb-4 profile-header p-4 rounded shadow-hover border-light-10">
          <div className="col-md-3 text-center">
            <div className="profile-image-container">
              <img
                src={formData.profileImage || "/api/placeholder/150/150"}
                className="profile-image"
                alt=""
              />
              <label className="profile-image-upload" htmlFor="profileImage">
                <Camera size={16} />
                <input
                  type="file"
                  id="profileImage"
                  className="d-none"
                  accept="image/*"
                  onChange={handleImageUpload}
                />
              </label>
            </div>
          </div>
          <div className="col-md-9">
            <h1 className="display-6 mb-2">
              {formData.firstName} {formData.lastName}
            </h1>
            <p className="text-white mb-3">{formData.email}</p>
            <div className="d-flex gap-2 flex-wrap">
              {subscriptionData && (
                <span className="profile-badge profile-badge-primary">
                  {subscriptionData.plan_name}
                </span>
              )}
              <span className="profile-badge profile-badge-success">Cuenta Verificada</span>
            </div>
          </div>
        </div>

        {/* Notification */}
        {notification && (
          <div className={`profile-alert ${notification.type === 'success' ? 'alert-success' : 'alert-danger'} alert-dismissible fade show`}>
            {notification.message}
            <button type="button" className="btn-close" onClick={() => setNotification(null)}></button>
          </div>
        )}

        {/* Navigation */}
        <ul className="nav profile-tabs">
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'overview' ? 'active' : ''}`}
              onClick={() => setActiveTab('overview')}
            >
              Vista General
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'profile' ? 'active' : ''}`}
              onClick={() => setActiveTab('profile')}
            >
              Perfil
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'security' ? 'active' : ''}`}
              onClick={() => setActiveTab('security')}
            >
              Seguridad
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'billing' ? 'active' : ''}`}
              onClick={() => setActiveTab('billing')}
            >
              Facturación
            </button>
          </li>
        </ul>

        {/* Content */}
        <div className="row">
          {/* Overview Tab */}
          {activeTab === 'overview' && (
            <div className="col-12 fade-in">
              <div className="row g-4">
                {/* Plan Card */}
                <div className="col-md-4">
                  <div className="profile-card h-100">
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <h5 className="card-title m-0">Plan Actual</h5>
                        <Crown className="text-primary" />
                      </div>
                      {subscriptionData && (
                        <>
                          <h3 className="mb-2">{subscriptionData.plan_name}</h3>
                          <p className="text-white">
                            Próximo pago: {new Date(subscriptionData.next_payment_date).toLocaleDateString()}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {/* Usage Card */}
                <div className="col-md-4">
                  <div className="profile-card h-100">
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <h5 className="card-title m-0">Uso</h5>
                        <Gauge className="text-primary" />
                      </div>
                      <div className="mt-4">
                        <p className="mb-2">Almacenamiento</p>
                        <div className="progress profile-progress mb-2">
                          <div
                            className="progress-bar"
                            style={{ width: '45%' }}
                            role="progressbar"
                            aria-valuenow="45"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <small className="text-white">45% utilizado</small>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Status Card */}
                <div className="col-md-4">
                  <div className="profile-card h-100">
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <h5 className="card-title m-0">Estado</h5>
                        <CheckCircle className="text-success" />
                      </div>
                      <span className="profile-badge profile-badge-success mb-3">Activo</span>
                      <p className="text-white mb-0">
                        Tu cuenta está en buen estado y todas las funcionalidades están disponibles.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Profile Tab */}
          {activeTab === 'profile' && (
            <div className="col-12 fade-in">
              <div className="profile-card">
                <div className="card-body">
                  <form onSubmit={handleSave}>
                    {/* Personal Information */}
                    <h5 className="mb-4">Información Personal</h5>
                    <div className="row mb-4">
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Nombre</label>
                        <input
                          type="text"
                          className="form-control profile-form-control"
                          value={formData.firstName}
                          onChange={(e) => setFormData(prev => ({
                            ...prev,
                            firstName: e.target.value
                          }))}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Apellido</label>
                        <input
                          type="text"
                          className="form-control profile-form-control"
                          value={formData.lastName}
                          onChange={(e) => setFormData(prev => ({
                            ...prev,
                            lastName: e.target.value
                          }))}
                          required
                        />
                      </div>
                    </div>

                    {/* Business Information */}
                    <h5 className="mb-4">Información del Negocio</h5>
                    <div className="row mb-4">
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Nombre de la Empresa</label>
                        <input
                          type="text"
                          className="form-control profile-form-control"
                          value={formData.companyName}
                          onChange={(e) => setFormData(prev => ({
                            ...prev,
                            companyName: e.target.value
                          }))}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Industria</label>
                        <select
                          className="form-select profile-form-control"
                          value={formData.industry}
                          onChange={(e) => setFormData(prev => ({
                            ...prev,
                            industry: e.target.value
                          }))}
                          required
                        >
                          <option value="">Seleccionar industria</option>
                          {INDUSTRIES.map(industry => (
                            <option key={industry} value={industry.toLowerCase()}>
                              {industry}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {/* Save Button */}
                    <button 
                      type="submit" 
                      className="profile-btn"
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                          Guardando...
                        </>
                      ) : (
                        <>
                          <Save className="me-2" size={18} />
                          Guardar Cambios
                        </>
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          )}

          {/* Security Tab */}
          {activeTab === 'security' && (
            <div className="col-12 fade-in">
              <div className="profile-card mb-4">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <div>
                      <h5 className="mb-1">Contraseña</h5>
                      <p className="text-white small mb-0">Última actualización hace 3 meses</p>
                    </div>
                    <button 
                      className="profile-btn"
                      onClick={() => setShowPasswordDialog(true)}
                    >
                      Cambiar Contraseña
                    </button>
                  </div>
                  <ul className="text-white small mb-0">
                    <li>Usa al menos 8 caracteres</li>
                    <li>Incluye mayúsculas y minúsculas</li>
                    <li>Incluye números y símbolos</li>
                  </ul>
                </div>
              </div>

              <div className="profile-card">
                <div className="card-body">
                  <h5 className="text-danger mb-3">Zona de Peligro</h5>
                  <p className="text-white mb-4">
                    Una vez eliminada, no podrás recuperar tu cuenta y todos tus datos serán borrados permanentemente.
                  </p>
                  <button 
                    className="profile-btn bg-danger"
                    onClick={() => setShowDeleteDialog(true)}
                  >
                    <Trash2 className="me-2" size={18} />
                    Eliminar Cuenta
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Billing Tab */}
          {activeTab === 'billing' && (
            <div className="col-12 fade-in">
              {subscriptionData && (
                <div className="profile-card mb-4">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <div>
                        <h5 className="mb-1">{subscriptionData.plan_name}</h5>
                        <p className="text-white small mb-0">
                          Próximo pago: {new Date(subscriptionData.next_payment_date).toLocaleDateString()}
                        </p>
                      </div>
                      <span className="profile-badge profile-badge-success">Activo</span>
                    </div>
                    
                    <hr className="border-light-10" />
                    
                    <h6 className="mb-3">Características del Plan</h6>
                    <ul className="list-unstyled text-white">
                      {subscriptionData.features?.map((feature, index) => (
                        <li key={index} className="mb-2 d-flex align-items-center">
                          <CheckCircle className="text-success me-2" size={18} />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}

              <div className="profile-card">
                <div className="card-body">
                  <h5 className="mb-4">Historial de Pagos</h5>
                  <div className="list-group bg-transparent">
                    {[1, 2, 3].map((_, index) => (
                      <div key={index} className="list-group-item profile-list-group-item">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h6 className="mb-1">Plan Premium</h6>
                            <p className="text-white small mb-0">01/12/2024</p>
                          </div>
                          <div className="text-end">
                            <div className="mb-1">$99.00</div>
                            <span className="badge bg-success">Pagado</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Password Change Modal */}
      {showPasswordDialog && (
        <div className="profile-modal modal fade show d-block" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Cambiar Contraseña</h5>
                <button type="button" className="btn-close" onClick={() => setShowPasswordDialog(false)}></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label className="form-label">Contraseña Actual</label>
                  <input
                    type="password"
                    className="form-control profile-form-control"
                    value={formData.security.currentPassword}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      security: { ...prev.security, currentPassword: e.target.value }
                    }))}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Nueva Contraseña</label>
                  <input
                    type="password"
                    className="form-control profile-form-control"
                    value={formData.security.newPassword}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      security: { ...prev.security, newPassword: e.target.value }
                    }))}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Confirmar Nueva Contraseña</label>
                  <input
                    type="password"
                    className="form-control profile-form-control"
                    value={formData.security.confirmPassword}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      security: { ...prev.security, confirmPassword: e.target.value }
                    }))}
                    required
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button 
                  type="button" 
                  className="btn btn-secondary"
                  onClick={() => setShowPasswordDialog(false)}
                >
                  Cancelar
                </button>
                <button 
                  type="button" 
                  className="btn btn-primary"
                  onClick={handlePasswordChange}
                  disabled={loading}
                >
                  {loading ? 'Cambiando...' : 'Cambiar Contraseña'}
                </button>
              </div>
            </div>
          </div>
          {/* Modal Backdrop */}
          <div className="modal-backdrop fade show"></div>
        </div>
      )}

      {/* Delete Account Modal */}
      {showDeleteDialog && (
        <div className="profile-modal modal fade show d-block" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-danger">Eliminar Cuenta</h5>
                <button type="button" className="btn-close" onClick={() => setShowDeleteDialog(false)}></button>
              </div>
              <div className="modal-body">
                <div className="alert alert-danger d-flex align-items-center mb-4">
                  <AlertCircle className="me-2" size={20} />
                  Esta acción es permanente y no se puede deshacer. Se eliminarán todos tus datos.
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Escribe "ELIMINAR" para confirmar
                  </label>
                  <input
                    type="text"
                    className="form-control profile-form-control"
                    placeholder="ELIMINAR"
                    onChange={(e) => {
                      if (e.target.value === 'ELIMINAR') {
                        handleDeleteAccount();
                      }
                    }}
                    required
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button 
                  type="button" 
                  className="btn btn-secondary"
                  onClick={() => setShowDeleteDialog(false)}
                >
                  Cancelar
                </button>
                <button 
                  type="button" 
                  className="btn btn-danger"
                  onClick={handleDeleteAccount}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                      Eliminando...
                    </>
                  ) : (
                    <>
                      <Trash2 className="me-2" size={18} />
                      Eliminar Cuenta
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
          {/* Modal Backdrop */}
          <div className="modal-backdrop fade show"></div>
        </div>
      )}
    </div>
  );
};

export default ProfilePage;
