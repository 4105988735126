import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../components/context/AuthContext';
import UnifiedAccountsManager from './UnifiedAccountsComponents';
import { useLocation } from 'react-router-dom';
import {
  Link as LinkIcon,
  Check,
  AlertCircle,
  Settings,
  RefreshCw,
  Facebook,
  XCircle,
  RefreshCcw,
  CheckCircle,
  DollarSign,
} from 'lucide-react';
import { FaGoogle } from 'react-icons/fa';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

// Componentes de utilidad
const AccountCard = ({ children, className = "" }) => (
  <div className={`bg-gray-800 p-4 rounded-lg border border-gray-700 ${className}`}>
    {children}
  </div>
);
const AccountsPage = () => {
  return <UnifiedAccountsManager />;
};
const StatusBadge = ({ status, type = "default" }) => {
  const getStatusColors = () => {
    const statusMap = {
      ACTIVE: 'bg-green-900/50 text-green-400',
      ENABLED: 'bg-green-900/50 text-green-400',
      PAUSED: 'bg-yellow-900/50 text-yellow-400',
      DISABLED: 'bg-red-900/50 text-red-400',
      default: 'bg-gray-900/50 text-gray-400'
    };

    if (type === 'meta') {
      return status === 'ACTIVE' ? statusMap.ACTIVE : statusMap.PAUSED;
    }

    if (type === 'google') {
      return status === 'ENABLED' ? statusMap.ENABLED : statusMap.DISABLED;
    }

    return statusMap[status] || statusMap.default;
  };

  return (
    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColors()}`}>
      {status}
    </span>
  );
};

const LoadingSpinner = ({ size = "small", color = "blue" }) => {
  const sizeClasses = {
    small: "h-4 w-4",
    medium: "h-6 w-6",
    large: "h-8 w-8"
  };

  const colorClasses = {
    blue: "text-blue-400",
    red: "text-red-400",
    white: "text-white"
  };

  return (
    <RefreshCw 
      className={`${sizeClasses[size]} ${colorClasses[color]} animate-spin`} 
    />
  );
};

const GoogleAccountSelector = ({ accounts, selectedAccount, onSelectAccount }) => {
  const [isConnecting, setIsConnecting] = useState(false);
  const { auth, updateUser } = useContext(AuthContext);
  const API_URL = process.env.REACT_APP_API_URL;

  const handleAccountConnection = async (account) => {
    try {
      setIsConnecting(true);
      
      // Hacer la petición para establecer la cuenta activa
      const response = await axios.post(
        `${API_URL}/user/set-active-account`,
        {
          google_customer_id: account.customer_id
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` }
        }
      );

      if (response.status === 200) {
        // Actualizar el estado local
        onSelectAccount(account);
        
        // Actualizar el contexto global del usuario
        updateUser({
          ...auth.user,
          google_customer_id: account.customer_id,
          google_connected: true
        });
      }

    } catch (error) {
      console.error('Error connecting account:', error);
    } finally {
      setIsConnecting(false);
    }
  };

  return (
    <div className="mt-4 border-t border-gray-700 pt-4">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-sm font-medium text-gray-300">Seleccionar cuenta para operar</h3>
        {selectedAccount && (
          <div className="flex items-center text-sm text-green-400">
            <Check className="h-4 w-4 mr-1" />
            Cuenta activa
          </div>
        )}
      </div>

      <div className="space-y-2">
        {accounts.filter(account => !account.is_manager).map((account) => (
          <div
            key={account.customer_id}
            className={`p-4 rounded-lg border transition-colors ${
              selectedAccount?.customer_id === account.customer_id
                ? 'border-blue-500 bg-blue-900/20'
                : 'border-gray-700 hover:border-blue-500/50 hover:bg-blue-900/10'
            }`}
          >
            <div className="flex items-center justify-between">
              <div className="flex-1">
                <div className="font-medium text-gray-200">
                  {account.descriptive_name || `Cuenta ${account.customer_id}`}
                </div>
                <div className="text-xs text-gray-400">
                  ID: {account.customer_id}
                  <span className="mx-2">•</span>
                  {account.currency_code}
                </div>
              </div>

              <div className="flex items-center gap-2">
                {selectedAccount?.customer_id === account.customer_id ? (
                  <div className="flex items-center text-blue-400">
                    <Check className="h-5 w-5" />
                  </div>
                ) : (
                  <button
                    onClick={() => handleAccountConnection(account)}
                    disabled={isConnecting}
                    className="px-3 py-1.5 bg-blue-600 text-white rounded-lg text-sm hover:bg-blue-700 
                      transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
                  >
                    {isConnecting ? (
                      <>
                        <RefreshCw className="h-4 w-4 animate-spin" />
                        <span>Conectando...</span>
                      </>
                    ) : (
                      <>
                        <Settings className="h-4 w-4" />
                        <span>Usar cuenta</span>
                      </>
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Componente principal
const AccountsConnection = () => {
  const location = useLocation();
  const { auth, updateUser } = useContext(AuthContext);
  const [isConnectingMeta, setIsConnectingMeta] = useState(false);
  const [isConnectingGoogle, setIsConnectingGoogle] = useState(false);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState({
    meta: false,
    google: false
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [metaAccounts, setMetaAccounts] = useState([]);
  const [googleAccounts, setGoogleAccounts] = useState([]);
  const [selectedMetaAccount, setSelectedMetaAccount] = useState(null);
  const [selectedGoogleAccount, setSelectedGoogleAccount] = useState(null);
  const [expandedMetaAccount, setExpandedMetaAccount] = useState(null);
  const [expandedGoogleAccount, setExpandedGoogleAccount] = useState(null);
  const [metaCampaigns, setMetaCampaigns] = useState({});
  const [googleCampaigns, setGoogleCampaigns] = useState({});
  const [activeGoogleAccount, setActiveGoogleAccount] = useState(null);
  const [loadingCampaigns, setLoadingCampaigns] = useState({});

  // Formateo de moneda
  const formatCurrency = (amount) => {
    if (!amount) return '$0.00';
    return new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    }).format(amount);
  };

  useEffect(() => {
    if (auth.user?.meta_connected) {
      fetchMetaAccounts().then(() => {
        if (auth.user?.meta_ad_account_id) {
          const currentAccount = metaAccounts.find(
            acc => acc.account_id === auth.user.meta_ad_account_id
          );
          if (currentAccount) {
            setSelectedMetaAccount(currentAccount);
            fetchMetaCampaigns(currentAccount.account_id);
          }
        }
      });
    }
    if (auth.user?.google_connected || location.state?.googleConnected) {
      fetchGoogleAccounts();
    }
  }, [auth.user?.meta_connected, auth.user?.google_connected, location.state?.googleConnected]);
  useEffect(() => {
    if (auth.user?.meta_connected) {
      fetchMetaAccounts().then(() => {
        if (auth.user?.meta_ad_account_id) {
          const currentAccount = metaAccounts.find(
            acc => acc.account_id === auth.user.meta_ad_account_id
          );
          if (currentAccount) {
            setSelectedMetaAccount(currentAccount);
            fetchMetaCampaigns(currentAccount.account_id);
          }
        }
      });
    }
    if (auth.user?.google_connected || location.state?.googleConnected) {
      fetchGoogleAccounts();
    }
  }, [auth.user?.meta_connected, auth.user?.google_connected, location.state?.googleConnected]);


  // Google Ads funciones
  const fetchGoogleAccounts = async () => {
    try {
      setIsLoadingAccounts(prev => ({ ...prev, google: true }));
      setError('');
      
      const response = await axios.get(
        `${API_URL}/google_ads/accounts`,
        {
          headers: { Authorization: `Bearer ${auth.token}` }
        }
      );
      
      const formattedAccounts = response.data.map(account => ({
        ...account,
        isManager: account.is_manager || false,
        status: account.status || 'ENABLED',
        descriptive_name: account.descriptive_name || `Cuenta ${account.customer_id}`,
        currency_code: account.currency_code || 'USD',
        time_zone: account.time_zone || 'GMT'
      }));

      setGoogleAccounts(formattedAccounts);

    } catch (error) {
      console.error('Error fetching Google accounts:', error);
      setError(error.response?.data?.error || 'Error al obtener cuentas de Google');
    } finally {
      setIsLoadingAccounts(prev => ({ ...prev, google: false }));
    }
  };

  const connectGoogleAds = async () => {
    if (!selectedGoogleAccount) {
      setError('Por favor, selecciona una cuenta primero');
      return;
    }

    try {
      setIsConnectingGoogle(true);
      setError('');
      
      const response = await axios.post(
        `${API_URL}/connect_google_ads`,
        {
          customer_id: selectedGoogleAccount.customer_id
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` }
        }
      );
  
      const userData = {
        google_connected: true,
        google_customer_id: selectedGoogleAccount.customer_id
      };
  
      const updateResponse = await axios.put(
        `${API_URL}/user`,
        userData,
        {
          headers: { Authorization: `Bearer ${auth.token}` }
        }
      );
  
      updateUser(updateResponse.data);
      setSuccess('Conexión exitosa con Google Ads');
      
    } catch (error) {
      console.error('Error connecting to Google Ads:', error);
      setError(error.response?.data?.error || 'Error al conectar con Google Ads');
    } finally {
      setIsConnectingGoogle(false);
    }
  }; // Meta Ads funciones
  const connectMetaAds = () => {
    setIsConnectingMeta(true);
    const appId = process.env.REACT_APP_FACEBOOK_APP_ID;
    const redirectUri = encodeURIComponent(`${window.location.origin}/facebook-callback`);
    const state = Math.random().toString(36).substring(7);
    localStorage.setItem('facebook_auth_state', state);
    
    const scopes = [
      'email',
      'public_profile',
      'ads_read',
      'ads_management',
      'business_management'
    ].join(',');

    window.location.href = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${appId}&redirect_uri=${redirectUri}&scope=${scopes}&response_type=code&state=${state}`;
  };

  // Fetch functions
  const fetchMetaAccounts = async () => {
    try {
      setIsLoadingAccounts(prev => ({ ...prev, meta: true }));
      const response = await axios.get(
        `${API_URL}/api/meta/accounts`,
        {
          headers: { Authorization: `Bearer ${auth.token}` }
        }
      );
      setMetaAccounts(response.data);
    } catch (error) {
      setError(error.response?.data?.error || 'Error al obtener cuentas de Meta');
    } finally {
      setIsLoadingAccounts(prev => ({ ...prev, meta: false }));
    }
  };

  const fetchMetaCampaigns = async (accountId) => {
    if (loadingCampaigns[accountId]) return;
    
    setLoadingCampaigns(prev => ({ ...prev, [accountId]: true }));
    try {
      const response = await axios.get(
        `${API_URL}/api/meta/campaigns/${accountId}`,
        {
          headers: { Authorization: `Bearer ${auth.token}` }
        }
      );
      setMetaCampaigns(prev => ({
        ...prev,
        [accountId]: response.data
      }));
    } catch (error) {
      setError(error.response?.data?.error || 'Error al obtener campañas de Meta');
    } finally {
      setLoadingCampaigns(prev => ({ ...prev, [accountId]: false }));
    }
  };

  const fetchGoogleCampaigns = async (customerId) => {
    if (loadingCampaigns[customerId]) return;
    
    setLoadingCampaigns(prev => ({ ...prev, [customerId]: true }));
    try {
      const response = await axios.get(
        `${API_URL}/google_ads/campaigns/${customerId}`,
        {
          headers: { Authorization: `Bearer ${auth.token}` }
        }
      );

      const formattedCampaigns = (response.data || []).map(campaign => ({
        campaign_id: campaign.campaign_id,
        name: campaign.name,
        status: campaign.status,
        advertising_channel_type: campaign.advertising_channel_type,
        budget_amount_micros: campaign.budget_amount_micros,
        metrics: campaign.metrics || {
          impressions: 0,
          clicks: 0,
          cost: 0,
          conversions: 0,
          conversion_value: 0
        },
        start_date: campaign.start_date,
        end_date: campaign.end_date
      }));

      setGoogleCampaigns(prev => ({
        ...prev,
        [customerId]: formattedCampaigns
      }));
    } catch (error) {
      console.error('Error fetching Google campaigns:', error);
      if (error.response?.status === 401) {
        setError('La sesión de Google Ads ha expirado. Por favor, vuelva a conectar su cuenta.');
        updateUser({
          ...auth.user,
          google_connected: false,
          google_customer_id: null
        });
      } else {
        setError('Error al obtener campañas de Google Ads. Por favor, intente de nuevo.');
      }
    } finally {
      setLoadingCampaigns(prev => ({ ...prev, [customerId]: false }));
    }
  };

  // Disconnect functions
  const disconnectMetaAds = async () => {
    try {
      await axios.post(
        `${API_URL}/api/meta/disconnect`,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` }
        }
      );
      updateUser({
        ...auth.user,
        meta_connected: false,
        meta_ad_account_id: null
      });
      setMetaAccounts([]);
      setMetaCampaigns({});
      setSuccess('Cuenta de Meta Ads desconectada');
    } catch (error) {
      setError(error.response?.data?.error || 'Error al desconectar Meta Ads');
    }
  };

  const disconnectGoogleAds = async () => {
    try {
      await axios.post(
        `${API_URL}/api/google_ads/disconnect`,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` }
        }
      );
      updateUser({
        ...auth.user,
        google_connected: false,
        google_customer_id: null
      });
      setGoogleAccounts([]);
      setGoogleCampaigns({});
      setSelectedGoogleAccount(null);
      setSuccess('Cuenta de Google Ads desconectada');
    } catch (error) {
      setError(error.response?.data?.error || 'Error al desconectar Google Ads');
    }
  };
  const handleGoogleAccountSelect = async (account) => {
    try {
      const response = await axios.post(
        `${API_URL}/user/set-active-account`,
        {
          google_customer_id: account.customer_id
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` }
        }
      );

      if (response.data.success) {
        setActiveGoogleAccount(account);
        setSelectedGoogleAccount(account);
        // Actualizar el usuario en el contexto de autenticación
        updateUser(response.data.user);
      }
    } catch (error) {
      console.error('Error setting active account:', error);
      setError(error.response?.data?.error || 'Error al seleccionar la cuenta');
    }
  };

  const selectMetaAccount = async (account) => {
    try {
      await axios.post(
        `${API_URL}/api/meta/select-account`,
        { account_id: account.account_id },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      setSelectedMetaAccount(account);
      updateUser({
        ...auth.user,
        meta_ad_account_id: account.account_id,
      });
      setSuccess('Cuenta de Meta Ads actualizada correctamente');
      await fetchMetaCampaigns(account.account_id);
    } catch (error) {
      console.error('Error selecting Meta account:', error);
      setError(`Error al seleccionar la cuenta: ${error.message}`);
    }
  };
  // Render sections
  const renderGoogleConnectSection = () => {
    const isConnected = auth.user?.google_connected;
      // Function to handle Google login
  const handleGoogleLogin = () => {
    // Store current auth state to preserve Meta connection
    if (auth.user?.meta_connected) {
      localStorage.setItem('preserveMetaConnection', 'true');
    }
       // Redirect to Google OAuth
       const redirectUri = `${window.location.origin}/google-callback`;
       const scope = encodeURIComponent('openid email profile https://www.googleapis.com/auth/adwords');
       window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code&access_type=offline&prompt=consent`;
     };
     
    
    
  if (isConnected) {
    return (
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <span className="flex items-center text-green-400">
              <Check className="h-5 w-5 mr-1" />
              <span className="text-sm font-medium">Conectado</span>
            </span>
            <button
              onClick={disconnectGoogleAds}
              className="p-1.5 rounded-full text-gray-400 hover:text-red-400 hover:bg-red-900/20"
              title="Desconectar cuenta"
            >
              <XCircle className="h-5 w-5" />
            </button>
          </div>
          {activeGoogleAccount && (
            <div className="text-sm text-gray-300">
              Cuenta activa: {activeGoogleAccount.descriptive_name}
            </div>
          )}
        </div>

        <GoogleAccountSelector
          accounts={googleAccounts}
          selectedAccount={selectedGoogleAccount}
          onSelectAccount={setSelectedGoogleAccount}
        />
      </div>
    );
  }

        
  return (
    <div className="space-y-4 w-full">
      {!googleAccounts.length && (
        <button
          onClick={handleGoogleLogin}
          className="w-full px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-lg flex items-center justify-center gap-2"
        >
          <FaGoogle className="h-4 w-4" />
          <span>Conectar con Google Ads</span>
        </button>
      )}

      {googleAccounts.length > 0 && (
        <>
          <GoogleAccountSelector
            accounts={googleAccounts}
            selectedAccount={selectedGoogleAccount}
            onSelectAccount={setSelectedGoogleAccount}
          />

          <button
            onClick={connectGoogleAds}
            disabled={isConnectingGoogle || !selectedGoogleAccount}
            className={`w-full px-4 py-2 rounded-lg flex items-center justify-center gap-2 transition-all 
              ${selectedGoogleAccount 
                ? 'bg-red-600 hover:bg-red-700 text-white' 
                : 'bg-gray-700 text-gray-400 cursor-not-allowed'}`}
          >
            {isConnectingGoogle ? (
              <>
                <LoadingSpinner color="white" />
                <span>Conectando...</span>
              </>
            ) : (
              <>
                <LinkIcon className="h-4 w-4" />
                <span>Conectar cuenta seleccionada</span>
              </>
            )}
          </button>

          {!selectedGoogleAccount && (
            <p className="text-sm text-red-400 text-center">
              Selecciona una cuenta para continuar
            </p>
          )}
        </>
      )}
    </div>
  );
};
  const renderGoogleSection = () => (
    <div className="bg-gray-800 rounded-xl shadow-lg overflow-hidden border border-gray-700">
      <div className="p-6 border-b border-gray-700">
        <div className="flex flex-col space-y-4">
          <div className="flex items-center space-x-4">
            <div className="p-3 rounded-full bg-red-900/50">
              <FaGoogle className="h-6 w-6 text-red-400" />
            </div>
            <div>
              <h2 className="text-lg font-semibold text-gray-100">Google Ads</h2>
              <p className="text-sm text-gray-400">
                {auth.user?.google_connected 
                  ? 'Cuenta conectada'
                  : googleAccounts.length 
                    ? 'Selecciona una cuenta para conectar'
                    : 'Obtén las cuentas disponibles'}
              </p>
            </div>
          </div>
          
          {renderGoogleConnectSection()}
        </div>
      </div>

      {auth.user?.google_connected && (
        <div className="p-6">
          {isLoadingAccounts.google ? (
            <div className="flex justify-center py-8">
              <LoadingSpinner size="large" color="red" />
            </div>
          ) : googleAccounts.length > 0 ? (
            <div className="space-y-4">
              {googleAccounts.map((account) => (
                <div key={account.customer_id} className="border border-gray-700 rounded-lg">
                  <div className="p-4" onClick={() => {
                    setExpandedGoogleAccount(expandedGoogleAccount === account.customer_id ? null : account.customer_id);
                    if (!googleCampaigns[account.customer_id]) {
                      fetchGoogleCampaigns(account.customer_id);
                    }
                  }}>
                    <div className="flex justify-between items-center">
                      <div>
                        <h3 className="font-medium text-gray-100">{account.descriptive_name}</h3>
                        <p className="text-sm text-gray-400">ID: {account.customer_id}</p>
                      </div>
                      <StatusBadge status={account.status} type="google" />
                    </div>
                  </div>

                  {expandedGoogleAccount === account.customer_id && (
                    <div className="border-t border-gray-700 p-4">
                      <div className="grid grid-cols-3 gap-4 mb-4">
                        <AccountCard>
                          <div className="text-sm text-gray-400 mb-1">Moneda</div>
                          <div className="text-lg font-semibold text-gray-100">
                            {account.currency_code}
                          </div>
                        </AccountCard>
                        <AccountCard>
                          <div className="text-sm text-gray-400 mb-1">Campañas activas</div>
                          <div className="text-lg font-semibold text-gray-100">
                            {googleCampaigns[account.customer_id]?.filter(c => c.status === 'ENABLED').length || 0}
                          </div>
                        </AccountCard>
                        <AccountCard>
                          <div className="text-sm text-gray-400 mb-1">Total campañas</div>
                          <div className="text-lg font-semibold text-gray-100">
                            {googleCampaigns[account.customer_id]?.length || 0}
                          </div>
                        </AccountCard>
                      </div>

                      <div className="space-y-4">
                        <div className="flex justify-between items-center">
                          <h4 className="text-sm font-medium text-gray-100">Campañas</h4>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              fetchGoogleCampaigns(account.customer_id);
                            }}
                            className="text-sm text-gray-400 hover:text-gray-300 flex items-center"
                          >
                            <RefreshCcw className="h-4 w-4 mr-1" />
                            Actualizar
                          </button>
                        </div>

                        {loadingCampaigns[account.customer_id] ? (
                          <div className="flex justify-center py-4">
                            <LoadingSpinner size="medium" color="red" />
                          </div>
                        ) : googleCampaigns[account.customer_id]?.length > 0 ? (
                          <div className="space-y-3">
                            {googleCampaigns[account.customer_id].map((campaign) => (
                              <AccountCard key={campaign.campaign_id}>
                                <div className="flex justify-between items-center">
                                  <div>
                                    <h5 className="font-medium text-gray-100">{campaign.name}</h5>
                                    <p className="text-sm text-gray-400">
                                      {campaign.advertising_channel_type}
                                      <span className="mx-2">•</span>
                                      {formatCurrency(campaign.budget_amount_micros / 1e6)}/día
                                    </p>
                                    <div className="mt-2 grid grid-cols-4 gap-4 text-xs text-gray-500">
                                      <div>
                                        <span className="block">Clicks</span>
                                        <span className="font-medium text-gray-300">
                                          {campaign.metrics.clicks}
                                        </span>
                                      </div>
                                      <div>
                                        <span className="block">Impresiones</span>
                                        <span className="font-medium text-gray-300">
                                          {campaign.metrics.impressions}
                                        </span>
                                      </div>
                                      <div>
                                        <span className="block">Costo</span>
                                        <span className="font-medium text-gray-300">
                                          {formatCurrency(campaign.metrics.cost)}
                                        </span>
                                      </div>
                                      <div>
                                        <span className="block">Conversiones</span>
                                        <span className="font-medium text-gray-300">
                                          {campaign.metrics.conversions}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <StatusBadge status={campaign.status} type="google" />
                                </div>
                              </AccountCard>
                            ))}
                          </div>
                        ) : (
                          <div className="text-center py-4 text-gray-400">
                            No hay campañas activas
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-6 text-gray-400">
              No se encontraron cuentas de Google Ads
            </div>
          )}
        </div>
      )}
    </div>
  );

  const renderMetaSection = () => (
    <div className="bg-gray-800 rounded-xl shadow-lg overflow-hidden border border-gray-700">
      <div className="p-6 border-b border-gray-700">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <div className="p-3 rounded-full bg-blue-900/50">
              <Facebook className="h-6 w-6 text-blue-400" />
            </div>
            <div>
              <h2 className="text-lg font-semibold text-gray-100">Meta Ads</h2>
              <p className="text-sm text-gray-400">
                {auth.user?.meta_connected ? 'Cuenta conectada' : 'Conecta tu cuenta de Meta Ads'}
              </p>
            </div>
          </div>
  
          {auth.user?.meta_connected ? (
            <div className="flex items-center space-x-3">
              <span className="flex items-center text-green-400">
                <Check className="h-5 w-5 mr-1" />
                <span className="text-sm font-medium">Conectado</span>
              </span>
              <button
                onClick={disconnectMetaAds}
                className="p-1.5 rounded-full text-gray-400 hover:text-red-400 hover:bg-red-900/20"
                title="Desconectar cuenta"
              >
                <XCircle className="h-5 w-5" />
              </button>
            </div>
          ) : (
            <button
              onClick={connectMetaAds}
              disabled={isConnectingMeta}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 disabled:opacity-50"
            >
              {isConnectingMeta ? (
                <>
                  <LoadingSpinner color="white" />
                  <span className="ml-2">Conectando...</span>
                </>
              ) : (
                <>
                  <LinkIcon className="h-4 w-4 mr-2" />
                  <span>Conectar</span>
                </>
              )}
            </button>
          )}
        </div>
      </div>
  
      {auth.user?.meta_connected && (
        <div className="p-6">
          {isLoadingAccounts.meta ? (
            <div className="flex justify-center py-8">
              <LoadingSpinner size="large" color="blue" />
            </div>
          ) : metaAccounts.length > 0 ? (
            <div className="space-y-4">
              {metaAccounts.map((account) => (
                <div key={account.account_id} className="border border-gray-700 rounded-lg">
                  <div className="p-4" onClick={() => {
                    setExpandedMetaAccount(expandedMetaAccount === account.account_id ? null : account.account_id);
                    if (!metaCampaigns[account.account_id]) {
                      fetchMetaCampaigns(account.account_id);
                    }
                  }}>
                    <div className="flex justify-between items-center">
                      <div>
                        <h3 className="font-medium text-gray-100">{account.name}</h3>
                        <p className="text-sm text-gray-400">ID: {account.account_id}</p>
                      </div>
                      <div className="flex items-center space-x-3">
                        <StatusBadge status={account.status} type="meta" />
                        {!account.is_selected && (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              selectMetaAccount(account);
                            }}
                            className="inline-flex items-center px-3 py-1 text-sm font-medium text-blue-400 bg-blue-900/20 rounded-md hover:bg-blue-900/40 transition-colors"
                          >
                            Seleccionar
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
  
                  {expandedMetaAccount === account.account_id && (
                    <div className="border-t border-gray-700 p-4">
                      <div className="grid grid-cols-3 gap-4 mb-4">
                        <AccountCard>
                          <div className="text-sm text-gray-400 mb-1">Balance</div>
                          <div className="text-lg font-semibold text-gray-100">
                            {formatCurrency(account.balance)}
                          </div>
                        </AccountCard>
                        <AccountCard>
                          <div className="text-sm text-gray-400 mb-1">Campañas activas</div>
                          <div className="text-lg font-semibold text-gray-100">
                            {metaCampaigns[account.account_id]?.filter(c => c.status === 'ACTIVE').length || 0}
                          </div>
                        </AccountCard>
                        <AccountCard>
                          <div className="text-sm text-gray-400 mb-1">Total campañas</div>
                          <div className="text-lg font-semibold text-gray-100">
                            {metaCampaigns[account.account_id]?.length || 0}
                          </div>
                        </AccountCard>
                      </div>
  
                      <div className="space-y-4">
                        <div className="flex justify-between items-center">
                          <h4 className="text-sm font-medium text-gray-100">Campañas</h4>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              fetchMetaCampaigns(account.account_id);
                            }}
                            className="text-sm text-gray-400 hover:text-gray-300 flex items-center"
                          >
                            <RefreshCcw className="h-4 w-4 mr-1" />
                            Actualizar
                          </button>
                        </div>
  
                        {loadingCampaigns[account.account_id] ? (
                          <div className="flex justify-center py-4">
                            <LoadingSpinner size="medium" color="blue" />
                          </div>
                        ) : metaCampaigns[account.account_id]?.length > 0 ? (
                          <div className="space-y-3">
                            {metaCampaigns[account.account_id].map((campaign) => (
                              <AccountCard key={campaign.id}>
                                <div className="flex justify-between items-center">
                                  <div>
                                    <h5 className="font-medium text-gray-100">{campaign.name}</h5>
                                    <p className="text-sm text-gray-400">
                                      {campaign.objective}
                                      <span className="mx-2">•</span>
                                      {formatCurrency(campaign.budget)}/día
                                    </p>
                                    {campaign.insights && (
                                      <div className="mt-2 grid grid-cols-4 gap-4 text-xs text-gray-500">
                                        <div>
                                          <span className="block text-gray-400">Alcance</span>
                                          <span className="font-medium text-gray-300">
                                            {campaign.insights.reach?.toLocaleString()}
                                          </span>
                                        </div>
                                        <div>
                                          <span className="block text-gray-400">Impresiones</span>
                                          <span className="font-medium text-gray-300">
                                            {campaign.insights.impressions?.toLocaleString()}
                                          </span>
                                        </div>
                                        <div>
                                          <span className="block text-gray-400">Clicks</span>
                                          <span className="font-medium text-gray-300">
                                            {campaign.insights.clicks?.toLocaleString()}
                                          </span>
                                        </div>
                                        <div>
                                          <span className="block text-gray-400">Gasto</span>
                                          <span className="font-medium text-gray-300">
                                            {formatCurrency(campaign.insights.spend)}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <StatusBadge status={campaign.status} type="meta" />
                                </div>
                              </AccountCard>
                            ))}
                          </div>
                        ) : (
                          <div className="text-center py-4 text-gray-400">
                            No hay campañas activas
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-6 text-gray-400">
              No se encontraron cuentas de Meta Ads
            </div>
          )}
        </div>
      )}
    </div>
  );

  // Main render
  return (
    <div className="min-h-screen bg-gray-900 p-6">
      <div className="max-w-7xl mx-auto space-y-6">
        {/* Header */}
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center space-x-4">
            <div className="p-3 rounded-full bg-indigo-900/50">
              <Settings className="w-6 h-6 text-indigo-400" />
            </div>
            <div>
              <h1 className="text-2xl font-bold text-gray-100">Conexión de Cuentas</h1>
              <p className="text-sm text-gray-400">Gestiona tus conexiones con plataformas publicitarias</p>
            </div>
          </div>
        </div>

        {/* Alerts */}
        {error && (
          <div className="flex items-center p-4 bg-red-900/30 border border-red-700 rounded-lg text-red-400">
            <AlertCircle className="h-5 w-5 mr-2" />
            <p>{error}</p>
          </div>
        )}
        {success && (
          <div className="flex items-center p-4 bg-green-900/30 border border-green-700 rounded-lg text-green-400">
            <CheckCircle className="h-5 w-5 mr-2" />
            <p>{success}</p>
          </div>
        )}

        {/* Account sections */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {renderMetaSection()}
          {renderGoogleSection()}
        </div>
      </div>
    </div>
  );
};

export default AccountsConnection;