import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import AICampaignCreation from '../../components/AiCampaignCreation';
import Sidebar from '../../components/sidebar';
import OnboardingModal from '../../components/OnboardingModal';
import { useAuth } from '../../components/context/AuthContext';
import { Loader, XCircle, CheckCircle } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL;

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth, updateUser, logout } = useAuth();

  // UI States
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isNewCampaignModalOpen, setIsNewCampaignModalOpen] = useState(false);

  // Campaign States
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  // Google Account States
  const [selectedCustomerId, setSelectedCustomerId] = useState(auth.user?.google_customer_id);
  const [managerCustomerId, setManagerCustomerId] = useState(auth.user?.google_manager_id);

  // Fetch Campaigns
  const fetchCampaigns = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/campaigns`, {
        headers: { Authorization: `Bearer ${auth.token}` }
      });
      setCampaigns(response.data.campaigns || []);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      setError('Error al cargar las campañas');
      setTimeout(() => setError(''), 3000);
    }
  }, [auth.token]);

  // Handle Google Auth
  useEffect(() => {
    const handleGoogleAuth = async () => {
      const params = new URLSearchParams(location.search);
      const code = params.get('code');
      const state = params.get('state');
      
      if (code && state) {
        const savedState = localStorage.getItem('google_auth_state');
        if (state === savedState) {
          setIsLoadingGoogle(true);
          try {
            const response = await axios.post(
              `${API_URL}/google/callback`,
              { code },
              {
                headers: {
                  'Authorization': `Bearer ${auth.token}`
                }
              }
            );

            if (response.data?.google_customer_id) {
              const updatedUserData = {
                ...auth.user,
                google_connected: true,
                google_customer_id: response.data.google_customer_id,
                google_manager_id: response.data.google_manager_id
              };
              await updateUser(updatedUserData);

              setSelectedCustomerId(response.data.google_customer_id);
              setManagerCustomerId(response.data.google_manager_id);
              setSuccess('Conexión con Google Ads exitosa');
              setTimeout(() => setSuccess(''), 3000);
            }
          } catch (error) {
            console.error('Error en autenticación de Google:', error);
            setError(error.response?.data?.error || 'Error al conectar con Google Ads');
            setTimeout(() => setError(''), 3000);
          } finally {
            setIsLoadingGoogle(false);
            window.history.replaceState({}, document.title, window.location.pathname);
            localStorage.removeItem('google_auth_state');
          }
        }
      }
    };

    handleGoogleAuth();
  }, [location.search, auth.token, auth.user, updateUser]);

  // Check Onboarding Status
  useEffect(() => {
    const checkOnboardingStatus = async () => {
      if (auth.token) {
        try {
          const response = await axios.get(
            `${API_URL}/user/onboarding-status`,
            {
              headers: {
                'Authorization': `Bearer ${auth.token}`
              }
            }
          );
          
          if (response.data) {
            setShowOnboarding(!response.data.onboarding_completed);
            if (response.data.user_data) {
              updateUser(response.data.user_data);
            }
          }
        } catch (error) {
          console.error('Error checking onboarding status:', error);
          setError('Error al verificar el estado de onboarding');
        }
      }
      setIsLoading(false);
    };

    checkOnboardingStatus();
  }, [auth.token, updateUser]);

  // Handle Resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobileOrTablet(window.innerWidth < 1024);
      if (window.innerWidth >= 1024) {
        setSidebarExpanded(false);
      }
    };
    
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Load Initial Campaigns
  useEffect(() => {
    fetchCampaigns();
  }, [fetchCampaigns]);

  // Handle Logout
  const handleLogout = useCallback(() => {
    try {
      logout();
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
      setError('Error al cerrar sesión');
      setTimeout(() => setError(''), 3000);
    }
  }, [logout, navigate]);

  // Handle Onboarding Complete
  const handleOnboardingComplete = async (formData) => {
    try {
      if (!formData) {
        throw new Error('No se recibieron datos del formulario');
      }

      const response = await axios.post(
        `${API_URL}/api/user/onboarding`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${auth.token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data) {
        setShowOnboarding(false);
        const updatedUserData = {
          ...response.data,
          onboarding_completed: true
        };
        await updateUser(updatedUserData);
        setSuccess('¡Configuración inicial completada!');
        setTimeout(() => setSuccess(''), 3000);
      }
    } catch (error) {
      console.error('Error completing onboarding:', error);
      const errorMessage = error.response?.data?.error || error.message || 'Error al completar la configuración inicial';
      setError(errorMessage);
      setTimeout(() => setError(''), 3000);
    }
  };

  // Loading State
  if (isLoading || isLoadingGoogle) {
    return (
      <div className="flex h-screen items-center justify-center bg-gray-900">
        <div className="text-center">
          <Loader className="w-8 h-8 text-blue-500 animate-spin mx-auto mb-4" />
          <p className="text-gray-400">
            {isLoadingGoogle ? 'Conectando con Google Ads...' : 'Cargando tu espacio de trabajo...'}
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex h-screen bg-gray-900 text-gray-100">
        <Sidebar 
          campaigns={campaigns}
          selectedCampaign={selectedCampaign}
          setSelectedCampaign={setSelectedCampaign}
          setIsNewCampaignModalOpen={setIsNewCampaignModalOpen}
          handleLogout={handleLogout}
          onExpandedChange={setSidebarExpanded}
        />

        <main className={`flex-1 relative transition-all duration-300 ease-in-out
          ${!isMobileOrTablet && sidebarExpanded ? 'lg:ml-64' : 'lg:ml-16'}`}>
          {error && (
            <div className="fixed top-4 right-4 z-50 flex items-center bg-red-500/10 border border-red-500 text-red-400 px-4 py-2 rounded-lg">
              <XCircle className="w-4 h-4 mr-2" />
              <span>{error}</span>
            </div>
          )}
          {success && (
            <div className="fixed top-4 right-4 z-50 flex items-center bg-green-500/10 border border-green-500 text-green-400 px-4 py-2 rounded-lg">
              <CheckCircle className="w-4 h-4 mr-2" />
              <span>{success}</span>
            </div>
          )}

          {sidebarExpanded && isMobileOrTablet && (
            <div 
              className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden"
              onClick={() => setSidebarExpanded(false)}
            />
          )}
          
          <div className="h-full overflow-hidden bg-gray-900">
            <AICampaignCreation 
              selectedCustomerId={selectedCustomerId}
              managerCustomerId={managerCustomerId}
              onCampaignCreated={(newCampaign) => {
                setSuccess('¡Campaña creada exitosamente!');
                setTimeout(() => setSuccess(''), 3000);
                fetchCampaigns();
              }}
              onError={(errorMsg) => {
                setError(errorMsg);
                setTimeout(() => setError(''), 3000);
              }}
            />
          </div>
        </main>
      </div>

      {showOnboarding && (
        <div className="fixed inset-0 z-[60] bg-gray-900/80 backdrop-blur-sm">
          <OnboardingModal 
            onComplete={handleOnboardingComplete}
            className="bg-gray-800 text-gray-100 border border-gray-700 shadow-xl"
          />
        </div>
      )}
    </>
  );
};

export default Dashboard;