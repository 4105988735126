import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../components/context/AuthContext';
import { Card, Container, Row, Col, Form } from 'react-bootstrap';
import { 
  BarChart2, 
  TrendingUp, 
  Users, 
  DollarSign
} from 'lucide-react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell
} from 'recharts';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const Analytics = () => {
  const { auth } = useContext(AuthContext);
  const [dateRange, setDateRange] = useState('7d');
  const [platformFilter, setPlatformFilter] = useState('all');
  
  // State for campaigns and metrics
  const [metaCampaigns, setMetaCampaigns] = useState([]);
  const [googleCampaigns, setGoogleCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCampaigns = async () => {
      setLoading(true);
      try {
        let fetchedMetaCampaigns = [];
        let fetchedGoogleCampaigns = [];
  
        // Fetch Meta Campaigns
        if (auth.user?.meta_connected && auth.user?.meta_ad_account_id) {
          const metaResponse = await axios.get(
            `${API_URL}/api/meta/campaigns/${auth.user.meta_ad_account_id}`,
            {
              headers: { Authorization: `Bearer ${auth.token}` },
              params: { dateRange }
            }
          );
          fetchedMetaCampaigns = metaResponse.data.map(campaign => ({
            ...campaign,
            platform: 'Meta Ads',
            impressions: campaign.insights?.impressions || 0,
            clicks: campaign.insights?.clicks || 0,
            conversions: campaign.insights?.conversions || 0,
            spend: campaign.insights?.spend || 0,
          }));
        }
  
        // Fetch Google Campaigns
        if (auth.user?.google_connected && auth.user?.google_customer_id) {
          const googleResponse = await axios.get(
            `${API_URL}/google_ads/campaigns/${auth.user.google_customer_id}`,
            {
              headers: { Authorization: `Bearer ${auth.token}` },
              params: { dateRange }
            }
          );
          fetchedGoogleCampaigns = googleResponse.data.map(campaign => ({
            ...campaign,
            platform: 'Google Ads',
            impressions: campaign.metrics?.impressions || 0,
            clicks: campaign.metrics?.clicks || 0,
            conversions: campaign.metrics?.conversions || 0,
            spend: campaign.metrics?.cost || 0,
          }));
        }
  
        setMetaCampaigns(fetchedMetaCampaigns);
        setGoogleCampaigns(fetchedGoogleCampaigns);
      } catch (err) {
        console.error('Error fetching campaigns:', err);
        setError(err.response?.data?.message || 'Error fetching campaign data');
      } finally {
        setLoading(false);
      }
    };
  
    fetchCampaigns();
  }, [auth.user?.meta_connected, auth.user?.meta_ad_account_id, auth.user?.google_connected, auth.user?.google_customer_id, dateRange, platformFilter]);

  // Combine and filter campaigns
  const allCampaigns = [
    ...metaCampaigns.map(campaign => ({
      ...campaign,
      platform: 'Meta Ads'
    })),
    ...googleCampaigns.map(campaign => ({
      ...campaign,
      platform: 'Google Ads'
    }))
  ].filter(campaign => 
    platformFilter === 'all' || 
    campaign.platform.toLowerCase().includes(platformFilter.toLowerCase())
  );

  const totalMetrics = allCampaigns.reduce((acc, campaign) => ({
    impressions: acc.impressions + campaign.impressions,
    clicks: acc.clicks + campaign.clicks,
    conversions: acc.conversions + campaign.conversions,
    spend: acc.spend + campaign.spend,
  }), { impressions: 0, clicks: 0, conversions: 0, spend: 0 });

  // Platform distribution data
  const platformData = [
    { name: 'Meta Ads', value: metaCampaigns.length },
    { name: 'Google Ads', value: googleCampaigns.length }
  ];

  const COLORS = ['#4299E1', '#48BB78'];

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      {/* Dark Mode Header */}
      <div className="bg-gray-800 border-b border-gray-700">
        <Container fluid className="py-4">
          <Row className="align-items-center">
            <Col>
              <h1 className="text-2xl font-bold text-white">Análisis de Campañas</h1>
              <p className="text-gray-400">Rendimiento detallado de tus campañas</p>
            </Col>
            <Col xs="auto">
              <div className="flex space-x-2">
                <Form.Select 
                  value={dateRange}
                  onChange={(e) => setDateRange(e.target.value)}
                  className="w-auto bg-gray-700 text-white border-gray-600 focus:ring-2 focus:ring-blue-500"
                >
                  <option value="7d" className="bg-gray-800">Últimos 7 días</option>
                  <option value="30d" className="bg-gray-800">Últimos 30 días</option>
                  <option value="90d" className="bg-gray-800">Últimos 90 días</option>
                </Form.Select>
                <Form.Select
                  value={platformFilter}
                  onChange={(e) => setPlatformFilter(e.target.value)}
                  className="w-auto bg-gray-700 text-white border-gray-600 focus:ring-2 focus:ring-blue-500"
                >
                  <option value="all" className="bg-gray-800">Todas las plataformas</option>
                  <option value="google" className="bg-gray-800">Google Ads</option>
                  <option value="meta" className="bg-gray-800">Meta Ads</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container fluid className="py-6">
        {/* Metrics Overview */}
        <Row className="mb-4">
          {[
            { 
              title: 'Impresiones',
              value: totalMetrics.impressions?.toLocaleString() || '0',
              icon: Users,
              color: 'blue'
            },
            {
              title: 'Clicks',
              value: totalMetrics.clicks?.toLocaleString() || '0',
              icon: TrendingUp,
              color: 'green'
            },
            {
              title: 'Conversiones',
              value: totalMetrics.conversions?.toLocaleString() || '0',
              icon: BarChart2,
              color: 'purple'
            },
            {
              title: 'Gasto Total',
              value: `$${totalMetrics.spend?.toFixed(2) || '0.00'}`,
              icon: DollarSign,
              color: 'orange'
            }
          ].map((metric, index) => (
            <Col key={index} md={3} className="mb-4">
              <div className="bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 h-full">
                <div className="p-4 flex items-center justify-between">
                  <div>
                    <div className="text-gray-400 text-sm">{metric.title}</div>
                    <div className="text-2xl font-bold text-white mt-1">{metric.value}</div>
                  </div>
                  <div className={`p-3 rounded-full bg-${metric.color}-900 bg-opacity-50`}>
                    <metric.icon className={`w-6 h-6 text-${metric.color}-400`} />
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>

        {/* Performance Charts */}
        <Row>
          <Col lg={8} className="mb-4">
            <div className="bg-gray-800 rounded-lg shadow-lg p-4">
              <h5 className="font-bold mb-4 text-white">Rendimiento en el Tiempo</h5>
              <div style={{ height: '400px' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart 
                    data={allCampaigns}
                    className="bg-gray-900 rounded-lg"
                  >
                    <CartesianGrid stroke="#374151" strokeDasharray="3 3" />
                    <XAxis 
                      dataKey="name" 
                      stroke="#9CA3AF" 
                      tick={{ fill: '#9CA3AF' }}
                    />
                    <YAxis 
                      stroke="#9CA3AF" 
                      tick={{ fill: '#9CA3AF' }}
                    />
                    <Tooltip 
                      contentStyle={{ 
                        backgroundColor: '#1F2937', 
                        borderColor: '#374151',
                        color: '#F3F4F6'
                      }}
                    />
                    <Line type="monotone" dataKey="impressions" stroke="#4299E1" name="Impresiones" />
                    <Line type="monotone" dataKey="clicks" stroke="#48BB78" name="Clicks" />
                    <Line type="monotone" dataKey="conversions" stroke="#ED64A6" name="Conversiones" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </Col>

          <Col lg={4} className="mb-4">
            <div className="bg-gray-800 rounded-lg shadow-lg p-4">
              <h5 className="font-bold mb-4 text-white">Distribución por Plataforma</h5>
              <div style={{ height: '400px' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={platformData}
                      cx="50%"
                      cy="50%"
                      outerRadius={120}
                      fill="#8884d8"
                      dataKey="value"
                      label={({name, value}) => `${name}: ${value}`}
                      labelStyle={{ fill: '#F3F4F6' }}
                    >
                      {platformData.map((entry, index) => (
                        <Cell 
                          key={`cell-${index}`} 
                          fill={COLORS[index % COLORS.length]} 
                        />
                      ))}
                    </Pie>
                    <Tooltip 
                      contentStyle={{ 
                        backgroundColor: '#1F2937', 
                        borderColor: '#374151',
                        color: '#F3F4F6'
                      }}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
          </Col>
        </Row>

        {/* Campaign Details Table */}
        <Row>
          <Col>
            <div className="bg-gray-800 rounded-lg shadow-lg">
              <div className="p-4">
                <h5 className="font-bold mb-4 text-white">Detalles de Campañas</h5>
                <div className="overflow-x-auto">
                  <table className="min-w-full">
                    <thead className="bg-gray-700">
                      <tr>
                        <th className="px-4 py-2 text-left text-gray-300">Campaña</th>
                        <th className="px-4 py-2 text-left text-gray-300">Plataforma</th>
                        <th className="px-4 py-2 text-right text-gray-300">Impresiones</th>
                        <th className="px-4 py-2 text-right text-gray-300">Clicks</th>
                        <th className="px-4 py-2 text-right text-gray-300">CTR</th>
                        <th className="px-4 py-2 text-right text-gray-300">Conversiones</th>
                        <th className="px-4 py-2 text-right text-gray-300">Gasto</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan="7" className="text-center py-4">
                            <div className="flex items-center justify-center">
                              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                            </div>
                          </td>
                        </tr>
                      ) : allCampaigns.length === 0 ? (
                        <tr>
                          <td colSpan="7" className="text-center py-4 text-gray-500">
                            No hay campañas disponibles
                          </td>
                        </tr>
                      ) : (
                        allCampaigns.map((campaign, index) => (
                          <tr 
                            key={index} 
                            className="border-t border-gray-700 hover:bg-gray-700 transition-colors duration-200"
                          >
                            <td className="px-4 py-3 font-medium text-white">{campaign.name}</td>
                            <td className="px-4 py-3">
                              <span className={`px-2 py-1 rounded-full text-sm ${
                                campaign.platform === 'Google Ads' 
                                  ? 'bg-blue-900 text-blue-300'
                                  : 'bg-green-900 text-green-300'
                              }`}>
                                {campaign.platform}
                              </span>
                            </td>
                            <td className="px-4 py-3 text-right text-gray-300">
                              {campaign.impressions?.toLocaleString() || '0'}
                            </td>
                            <td className="px-4 py-3 text-right text-gray-300">
                              {campaign.clicks?.toLocaleString() || '0'}
                            </td>
                            <td className="px-4 py-3 text-right text-gray-300">
                              {campaign.clicks && campaign.impressions 
                                ? `${((campaign.clicks / campaign.impressions) * 100).toFixed(2)}%`
                                : '0.00%'}
                            </td>
                            <td className="px-4 py-3 text-right text-gray-300">
                              {campaign.conversions?.toLocaleString() || '0'}
                            </td>
                            <td className="px-4 py-3 text-right text-gray-300 font-semibold">
                              ${campaign.spend?.toFixed(2) || '0.00'}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        {/* Error Handling */}
        {error && (
          <div className="bg-red-900 bg-opacity-20 border border-red-700 rounded-lg p-4 mt-4">
            <div className="flex items-center">
              <svg className="w-6 h-6 text-red-500 mr-3" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
              </svg>
              <p className="text-red-300">{error}</p>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Analytics;