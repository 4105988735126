// AuthContext.js
import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        
        // Verificar si el token ha expirado
        if (decoded.exp && decoded.exp > currentTime) {
          return { 
            token, 
            user: { 
              ...decoded,
              campaigns: [],
              google_connected: decoded.google_connected || false,
              meta_connected: decoded.meta_connected || false,
              google_customer_id: decoded.google_customer_id || null,
              meta_ad_account_id: decoded.meta_ad_account_id || null
            } 
          };
        } else {
          // Token expirado, limpiarlo
          localStorage.removeItem('access_token');
        }
      } catch (error) {
        console.error('Error decodificando el token JWT:', error);
        localStorage.removeItem('access_token');
      }
    }
    return { token: null, user: null };
  });

  // Agregar una función para validar el token
  const validateToken = useCallback(async (token) => {
    try {
      const response = await axios.get('/api/validate-token', {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data.valid;
    } catch (error) {
      console.error('Error validando token:', error);
      return false;
    }
  }, []);
  const [loading, setLoading] = useState(true);

  // Configurar interceptor de Axios para incluir el token en todas las peticiones
  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      config => {
        if (auth.token) {
          config.headers['Authorization'] = `Bearer ${auth.token}`;
        }
        return config;
      },
      error => Promise.reject(error)
    );

    return () => axios.interceptors.request.eject(requestInterceptor);
  }, [auth.token]);

  const refreshToken = useCallback(async () => {
    try {
      const response = await axios.post('/api/refresh-token', {}, {
        headers: {
          'Authorization': `Bearer ${auth.token}`
        }
      });

      if (response.data?.access_token) {
        localStorage.setItem('access_token', response.data.access_token);
        const decoded = jwtDecode(response.data.access_token);
        
        setAuth(prev => ({
          token: response.data.access_token,
          user: {
            ...prev.user,
            ...decoded,
            ...response.data.user
          }
        }));
        
        return response.data.access_token;
      }
      return null;
    } catch (error) {
      console.error('Error refreshing token:', error);
      logout();
      return null;
    }
  }, [auth.token]);

  // Configurar interceptor para refrescar token
  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      response => response,
      async error => {
        const originalRequest = error.config;
        
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          
          const newToken = await refreshToken();
          if (newToken) {
            originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
            return axios(originalRequest);
          }
        }
        
        return Promise.reject(error);
      }
    );

    return () => axios.interceptors.response.eject(responseInterceptor);
  }, [refreshToken]);

  const logout = useCallback(async () => {
    try {
      // Primero intentar hacer logout en el backend
      if (auth.token) {
        await axios.post('/api/logout', {}, {
          headers: { Authorization: `Bearer ${auth.token}` }
        }).catch(() => {
          // Ignorar errores del backend durante el logout
          console.log('Backend logout failed, continuing with local cleanup');
        });
      }
  
      // Limpiar el almacenamiento local
      localStorage.removeItem('access_token');
      localStorage.removeItem('google_auth_state');
      localStorage.removeItem('facebook_auth_state');
  
      // Limpiar los headers de axios
      delete axios.defaults.headers.common['Authorization'];
  
      // Limpiar el estado de auth
      setAuth({ token: null, user: null });
      
    } catch (error) {
      console.error('Error during logout:', error);
      // Asegurar que el estado se limpie incluso si hay un error
      setAuth({ token: null, user: null });
    }
  }, [auth.token]);
   // Modificar la función login
   const login = useCallback(async (token, userData = null) => {
    try {
      const isValid = await validateToken(token);
      if (!isValid) {
        throw new Error('Token inválido');
      }

      localStorage.setItem('access_token', token);
      const decoded = jwtDecode(token);
      
      setAuth({ 
        token, 
        user: { 
          ...decoded,
          ...userData,
          campaigns: [],
          google_connected: decoded.google_connected || false,
          meta_connected: decoded.meta_connected || false,
          google_customer_id: decoded.google_customer_id || null,
          meta_ad_account_id: decoded.meta_ad_account_id || null
        } 
      });

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return true;
    } catch (error) {
      console.error('Error en login:', error);
      logout();
      return false;
    }
  }, [logout, validateToken]);

  const updateUser = useCallback(async (userData) => {
    try {
      const response = await axios.put('/api/user', userData);
      
      if (response.data) {
        setAuth(prev => ({
          ...prev,
          user: { ...prev.user, ...response.data }
        }));
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error updating user:', error);
      if (error.response?.status === 401) {
        await refreshToken();
      }
      return false;
    }
  }, [refreshToken]);

  const addCampaigns = useCallback((campaigns) => {
    setAuth(prev => ({
      ...prev,
      user: {
        ...prev.user,
        campaigns
      }
    }));
  }, []);

  // Validar sesión al cargar
  useEffect(() => {
    const validateSession = async () => {
      if (auth.token) {
        try {
          const response = await axios.get('/api/validate-token');
          if (!response.data.valid) {
            logout();
          }
        } catch (error) {
          if (error.response?.status === 401) {
            const newToken = await refreshToken();
            if (!newToken) {
              logout();
            }
          }
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    validateSession();
  }, [auth.token, logout, refreshToken]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        login,
        logout,
        updateUser,
        addCampaigns,
        refreshToken,
        isAuthenticated: !!auth.token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth debe ser usado dentro de un AuthProvider');
  }
  return context;
};

// Configuración global de Axios
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';
