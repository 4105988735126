import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../components/context/AuthContext';
import { FcGoogle } from 'react-icons/fc';
import { FaFacebook } from 'react-icons/fa';
import { jwtDecode } from 'jwt-decode';

const Login = () => {
  const navigate = useNavigate();
  const { login, auth } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [fbLoaded, setFbLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(() => {
    // Si ya está autenticado y tiene token válido, redirigir al dashboard
    if (auth?.token) {
      try {
        const decodedToken = jwtDecode(auth.token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp && decodedToken.exp > currentTime) {
          navigate('/dashboard');
          return;
        }
      } catch (error) {
        console.error('Error validando token existente:', error);
      }
    }

    const loadFacebookSDK = () => {
      window.fbAsyncInit = function() {
        window.FB.init({
          appId: process.env.REACT_APP_FACEBOOK_APP_ID,
          cookie: true,
          xfbml: true,
          version: 'v18.0'
        });
        
        setFbLoaded(true);
        
        window.FB.getLoginStatus(function(response) {
          if (response.status === 'connected') {
            fetchFacebookUserData(response.authResponse.accessToken);
          }
        });
      };

      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); 
        js.id = id;
        js.src = "https://connect.facebook.net/es_LA/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    };

    loadFacebookSDK();

    // Verificar parámetros de URL solo si no estamos redirigiendo ya
    if (!isRedirecting) {
      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get('token');
      const authError = queryParams.get('error');

      if (token) {
        setIsRedirecting(true);
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;
          
          if (decodedToken.exp && decodedToken.exp > currentTime) {
            login(token);
            navigate('/dashboard');
          } else {
            setError('La sesión ha expirado. Por favor, inicie sesión nuevamente.');
            setIsRedirecting(false);
          }
        } catch (error) {
          console.error('Error al decodificar token:', error);
          setError('Error en la autenticación. Token inválido.');
          setIsRedirecting(false);
        }
      } else if (authError) {
        setError('Error en la autenticación. Por favor, intente nuevamente.');
      }
    }
  }, [login, navigate, auth?.token, isRedirecting]);

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
        credentials: 'include'
      });
      
      const data = await response.json();
      
      if (response.ok) {
        await login(data.access_token);
        navigate('/dashboard');
      } else {
        setError(data.error || 'Error en el inicio de sesión');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Error de conexión. Por favor, intente nuevamente.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleLogin = () => {
    setIsLoading(true);
    const googleAuthUrl = `${process.env.REACT_APP_API_URL}/login/google`;
    // Guardar la URL actual para redireccionar después del login
    localStorage.setItem('redirectAfterLogin', window.location.pathname);
    window.location.href = googleAuthUrl;
  };
  
  const handleMetaLogin = () => {
    if (!fbLoaded) {
      setError('Por favor, espere mientras se carga Facebook SDK');
      return;
    }

    setError('');
    setIsLoading(true);

    const requiredPermissions = [
      'email',
      'public_profile',
      'business_management',
      'ads_management',
      'ads_read',
      'instagram_basic',
      'pages_show_list',
      'pages_read_engagement'
    ];

    window.FB.login(
      function(response) {
        if (response.authResponse) {
          fetchFacebookUserData(response.authResponse.accessToken);
        } else {
          setError('Inicio de sesión con Facebook cancelado');
          setIsLoading(false);
        }
      },
      {
        config_id: process.env.REACT_APP_FACEBOOK_CONFIG_ID,
        scope: requiredPermissions.join(','),
        auth_type: 'rerequest',
        return_scopes: true
      }
    );
  };

  const fetchFacebookUserData = async (accessToken) => {
    try {
      const permissionsResponse = await fetch(
        `https://graph.facebook.com/me/permissions?access_token=${accessToken}`
      );
      const permissionsData = await permissionsResponse.json();
      
      if (!permissionsData.data.some(
        permission => permission.permission === 'email' && permission.status === 'granted'
      )) {
        throw new Error('Se requiere acceso al email para continuar');
      }

      const userDataResponse = await fetch(
        `https://graph.facebook.com/me?fields=id,name,email&access_token=${accessToken}`
      );
      const userData = await userDataResponse.json();

      const response = await fetch(`${process.env.REACT_APP_API_URL}/login/facebook/verify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          facebookId: userData.id,
          email: userData.email,
          name: userData.name,
          accessToken: accessToken,
          permissions: permissionsData.data
        }),
        credentials: 'include'
      });

      const data = await response.json();
      
      if (response.ok) {
        await login(data.access_token);
        navigate('/dashboard');
      } else {
        throw new Error(data.error || 'Error en la verificación');
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error.message || 'Error al procesar el inicio de sesión con Facebook');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col md:flex-row">
      {/* Banner izquierdo */}
      <div 
        className="hidden md:flex md:w-1/2 bg-cover bg-center justify-center items-center"
        style={{
          backgroundImage: `url('https://wallpapers.com/images/hd/world-globe-l658bqf4z68pij5a.jpg')`,
        }}
      >
        <div className="max-w-md text-white p-8 bg-black bg-opacity-50 rounded-lg">
          <h1 className="text-4xl font-bold mb-4">Build your success</h1>
          <p className="text-xl">Create successful marketing campaigns.</p>
        </div>
      </div>

      {/* Contenedor del formulario */}
      <div className="flex-1 flex items-center justify-center p-8">
        <div className="max-w-md w-full space-y-8">
          {/* Logo y título */}
          <div className="text-center">
            <img
              className="mx-auto h-20 w-auto"
              src="https://us4-files.zohopublic.com/public/SalesIQ/download/d_709112831_operators_538979000007368049?x-cli-msg=%7B%22x-siq-soid%22%3A%22709112831%22%2C%22x-siq-module%22%3A%22operators%22%2C%22x-siq-type%22%3A%22operator_image%22%2C%22x-siq-parentid%22%3A%22538979000007368049%22%2C%22x-siq-resourceid%22%3A%223_538979000007368049%22%2C%22x-siq-filetype%22%3A%22image%2Fjpg%22%2C%22x-siq-lsid%22%3A%22siq9f570027249fba6be272b261a5da54dde1b06ce641713a54ae0bf775cf0dc387%22%2C%22x-siq-cachekey%22%3A1729013543233%2C%22x-siq-nps%22%3A%22202%22%7D"
              alt="Company logo"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Log in to your account
            </h2>
          </div>

          {/* Mensaje de error */}
          {error && (
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">{error}</h3>
                </div>
              </div>
            </div>
          )}

          {/* Formulario de login */}
          <form className="mt-8 space-y-6" onSubmit={handleEmailLogin}>
            <input type="hidden" name="remember" value="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">Email address</label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={isLoading}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">Password</label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={isLoading}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                disabled={isLoading}
              >
                {isLoading ? 'Iniciando sesión...' : 'Sign in'}
              </button>
            </div>
          </form>

          {/* Separador */}
          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">
                  Or continue with
                </span>
              </div>
            </div>

            {/* Link de registro */}
            <div className="mt-6 text-center">
              <p className="text-sm text-gray-600">
                ¿No tienes una cuenta?{' '}
                <a
                  href="/register"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Regístrate aquí
                </a>
              </p>
            </div>

            {/* Botones de redes sociales */}
            <div className="mt-6 grid grid-cols-2 gap-3">
              <button
                onClick={handleGoogleLogin}
                className="w-full flex items-center justify-center px-8 py-3 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                disabled={isLoading}
              >
                <FcGoogle className="h-5 w-5 mr-2" />
                Google
              </button>
              <button
                onClick={handleMetaLogin}
                className="w-full flex items-center justify-center px-8 py-3 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                disabled={isLoading || !fbLoaded}
              >
                <FaFacebook className="h-5 w-5 mr-2 text-blue-600" />
                Meta
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;