// AiCampaignCreation.js

import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  Search, ThumbsUp, MessageCircle, Share2, Clock, Menu, 
  Monitor, Smartphone, Send, Loader, Sparkles, Wand2, X,
  Globe, DollarSign, Target, Check, Upload, Image, AlertCircle, BarChart2,
  HelpCircle, Plus
} from 'lucide-react';

import { 
  Calendar, Store, CreditCard, Tag, 
  Package, Bell, BarChart3, 
} from 'lucide-react';
import { AuthContext } from '../components/context/AuthContext';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const Message = ({ message, isTyping }) => {
  const isAI = message.role === 'assistant';
  const isSystem = message.role === 'system';

  return (
    <div className={`flex items-end gap-2 mb-4 ${isAI ? 'justify-start' : 'justify-end'}`}>
      {isAI && (
        <div className="h-8 w-8 rounded-full bg-gradient-to-br from-blue-500 to-indigo-600 
          flex items-center justify-center flex-shrink-0">
          <Wand2 className="h-4 w-4 text-white" />
        </div>
      )}
      
      <div className={`max-w-[80%] px-4 py-3 shadow-md ${
        isAI ? 'bg-gray-800 rounded-3xl rounded-bl-md' : 
        isSystem ? 'bg-gray-900 rounded-3xl' :
        'bg-gradient-to-r from-blue-600 to-indigo-600 rounded-3xl rounded-br-md'
      }`}>
        {isTyping ? (
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-1 py-2">
              <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" 
                style={{ animationDelay: '0ms' }} />
              <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" 
                style={{ animationDelay: '150ms' }} />
              <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" 
                style={{ animationDelay: '300ms' }} />
            </div>
            <span className="text-xs text-gray-400">Generando respuesta...</span>
          </div>
        ) : (
          <>
            <div className="text-sm leading-relaxed text-gray-100">
              {message.content}
            </div>
            {message.timestamp && (
              <div className="text-[10px] mt-1.5 text-gray-400">
                {new Date(message.timestamp).toLocaleTimeString([], { 
                  hour: '2-digit', 
                  minute: '2-digit'
                })}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

// **Componente GooglePreview**
const GooglePreview = ({ campaignPreview }) => (
  <div className="bg-gray-800 p-4 rounded-xl shadow-md border border-gray-700 hover:shadow-lg transition-all duration-200">
    <div className="mb-3 flex items-center gap-2 border border-gray-700 rounded-full p-2 shadow-sm bg-gray-900">
      <Search className="w-4 h-4 text-blue-400" />
      <span className="text-sm text-gray-300 flex-1 truncate">
        {campaignPreview.adContent?.keywords?.[0] || `Búsqueda relacionada con ${campaignPreview.name}`}
      </span>
    </div>

    <div className="mb-1 inline-block text-[10px] px-2 py-0.5 bg-blue-900 text-blue-300 rounded-sm">
      Anuncio
    </div>

    <div className="space-y-2">
      <div>
        <div className="text-blue-400 text-base leading-tight cursor-pointer hover:underline">
          {campaignPreview.adContent?.titulos?.[0] || campaignPreview.name} | {campaignPreview.name}
        </div>
        <div className="text-emerald-400 text-xs mb-1">
          {campaignPreview.adContent?.urls?.display || "www.tudominio.com/landing"}
        </div>
        <div className="text-xs text-gray-300">
          {campaignPreview.adContent?.descripcion || 
           `Descubre cómo podemos ayudarte con ${campaignPreview.objective}. Presupuestos desde $${campaignPreview.budget}/día.`}
        </div>
      </div>
      
      <div className="grid grid-cols-3 gap-1 text-blue-400 text-xs pt-2 border-t border-gray-700">
        {(campaignPreview.adContent?.extensiones || ["Contacto", "Ubicaciones", "Reseñas"]).map((extension, index) => (
          <div key={index} className="cursor-pointer hover:underline p-1.5 rounded hover:bg-gray-700 transition-colors">
            {extension}
          </div>
        ))}
      </div>
    </div>
  </div>
);

// **Componente ImageUploadModal**
const ImageUploadModal = ({ isOpen, onClose, onUpload }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(null);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const handleFileSelect = (e) => {
    if (!e.target.files || e.target.files.length === 0) return;
    
    const file = e.target.files[0];
    if (!file.type.startsWith('image/')) {
      alert('Por favor selecciona una imagen');
      return;
    }

    setSelectedFile(file);
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append('image', selectedFile);

      await onUpload(formData);
      onClose();
    } catch (error) {
      console.error('Error uploading image:', error);
      alert('Error al subir la imagen');
    } finally {
      setIsUploading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black/60" onClick={onClose} />
      <div className="relative bg-gray-800 rounded-xl p-6 max-w-md w-full mx-4">
        <h3 className="text-lg font-semibold text-gray-100 mb-4">Subir imagen</h3>
        
        <div className="space-y-4">
          {/* Área de preview */}
          <div 
            onClick={() => fileInputRef.current?.click()}
            className={`relative aspect-video rounded-lg border-2 border-dashed 
              ${preview ? 'border-blue-500/50' : 'border-gray-600'} 
              hover:border-blue-500 transition-colors cursor-pointer
              overflow-hidden`}
          >
            {preview ? (
              <img 
                src={preview} 
                alt="Preview" 
                className="w-full h-full object-contain"
              />
            ) : (
              <div className="absolute inset-0 flex flex-col items-center justify-center text-gray-400">
                <Upload className="w-8 h-8 mb-2" />
                <p className="text-sm">Haz clic para seleccionar una imagen</p>
                <p className="text-xs mt-1">PNG, JPG o JPEG hasta 5MB</p>
              </div>
            )}
          </div>

          <input
            ref={fileInputRef}
            type="file"
            className="hidden"
            accept="image/*"
            onChange={handleFileSelect}
          />

          <div className="flex gap-2">
            <button
              onClick={onClose}
              className="flex-1 px-4 py-2 bg-gray-700 text-gray-300 rounded-lg
                hover:bg-gray-600 transition-colors text-sm"
            >
              Cancelar
            </button>
            <button
              onClick={handleUpload}
              disabled={!selectedFile || isUploading}
              className="flex-1 px-4 py-2 bg-blue-600 text-white rounded-lg
                hover:bg-blue-700 transition-colors text-sm disabled:opacity-50
                disabled:cursor-not-allowed flex items-center justify-center gap-2"
            >
              {isUploading ? (
                <>
                  <Loader className="w-4 h-4 animate-spin" />
                  <span>Subiendo...</span>
                </>
              ) : (
                'Subir imagen'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const MetaPreview = ({ 
  campaignPreview, 
  onUpdateCampaign,
  onUploadImage,
  onUpdateSettings,
  auth,
  setCampaignPreview
}) => {
  const [selectedImageFormat, setSelectedImageFormat] = useState('feed');
  const [images, setImages] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);
  
  const [campaignDates, setCampaignDates] = useState({
    startDate: campaignPreview?.metaSettings?.campaignDates?.startDate || '',
    endDate: campaignPreview?.metaSettings?.campaignDates?.endDate || ''
  });

  useEffect(() => {
    if (campaignPreview?.adContent?.image_urls) {
      setImages(campaignPreview.adContent.image_urls);
    }
  }, [campaignPreview?.adContent?.image_urls]);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else {
      setDragActive(false);
    }
  };

  const handleImageUploadLocal = async (file) => {
    if (!file) return;
    
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append('image', file);
      
      console.log('Uploading image...'); // Debug
      
      const response = await axios.post(`${API_URL}/api/meta/upload-image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${auth.token}`
        }
      });
  
      console.log('Upload response:', response.data); // Debug
  
      if (response.data.url) {
        setImages(prev => ({
          ...prev,
          [selectedImageFormat]: response.data.url
        }));
  
        setCampaignPreview(prev => ({
          ...prev,
          adContent: {
            ...prev.adContent,
            image_urls: {
              ...(prev.adContent?.image_urls || {}),
              [selectedImageFormat]: response.data.url
            }
          }
        }));
      }
    } catch (error) {
      console.error('Error uploading image:', error.response?.data || error);
      alert('Error al subir la imagen. Por favor, intenta de nuevo.');
    } finally {
      setIsUploading(false);
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      handleImageUploadLocal(file);
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    const file = e.dataTransfer.files?.[0];
    if (file) {
      await handleImageUploadLocal(file);
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-gray-800 rounded-2xl p-6 space-y-6">
        {/* Campaign Period */}
        <div className="space-y-3">
          <label className="block text-sm font-medium text-gray-200 flex items-center gap-2">
            <Clock className="w-4 h-4" />
            Periodo de campaña
          </label>
          <div className="grid grid-cols-2 gap-4">
            <input
              type="date"
              value={campaignDates.startDate}
              onChange={(e) => {
                const newDates = {
                  ...campaignDates,
                  startDate: e.target.value
                };
                setCampaignDates(newDates);
                onUpdateSettings?.({ campaignDates: newDates });
              }}
              className="bg-gray-700/50 border border-gray-600 rounded-xl px-4 py-3 text-sm text-gray-200"
            />
            <input
              type="date"
              value={campaignDates.endDate}
              onChange={(e) => {
                const newDates = {
                  ...campaignDates,
                  endDate: e.target.value
                };
                setCampaignDates(newDates);
                onUpdateSettings?.({ campaignDates: newDates });
              }}
              className="bg-gray-700/50 border border-gray-600 rounded-xl px-4 py-3 text-sm text-gray-200"
            />
          </div>
        </div>

        {/* Image Upload Section */}
        <div className="space-y-3">
          <label className="block text-sm font-medium text-gray-200">
            Imagen del anuncio
          </label>
          <div
            className={`relative overflow-hidden ${
              selectedImageFormat === 'story' ? 'aspect-[9/16]' : 
              selectedImageFormat === 'square' ? 'aspect-square' : 
              'aspect-[1.91/1]'
            } rounded-xl transition-all duration-300`}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            <input
              ref={fileInputRef}
              type="file"
              className="hidden"
              accept="image/*"
              onChange={handleFileChange}
            />
            
            {images[selectedImageFormat] ? (
              <div className="relative group">
                <img 
                  src={images[selectedImageFormat]}
                  alt="Ad Preview"
                  className="w-full h-full object-cover rounded-xl"
                />
                <div className="absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                  <button
                    onClick={() => fileInputRef.current?.click()}
                    className="px-4 py-2 bg-white/10 hover:bg-white/20 rounded-lg backdrop-blur-sm text-white text-sm font-medium transition-colors"
                  >
                    Cambiar imagen
                  </button>
                </div>
              </div>
            ) : (
              <button
                onClick={() => fileInputRef.current?.click()}
                className={`w-full h-full flex flex-col items-center justify-center border-2 border-dashed rounded-xl
                  ${dragActive ? 'border-blue-500 bg-blue-500/10' : 'border-gray-600 hover:border-blue-500/50 hover:bg-gray-700/50'}
                  transition-all duration-200`}
              >
                {isUploading ? (
                  <Loader className="w-6 h-6 text-gray-400 animate-spin" />
                ) : (
                  <>
                    <Upload className="w-6 h-6 text-gray-400 mb-2" />
                    <p className="text-sm text-gray-400">
                      {dragActive ? 'Suelta la imagen aquí' : 'Arrastra o haz clic para subir'}
                    </p>
                  </>
                )}
              </button>
            )}
          </div>

          {/* Image Format Selector */}
          <div className="flex gap-2">
            {['feed', 'story', 'square'].map(format => (
              <button
                key={format}
                onClick={() => setSelectedImageFormat(format)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-all ${
                  selectedImageFormat === format
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-700/50 text-gray-300 hover:bg-gray-700'
                }`}
              >
                {format.charAt(0).toUpperCase() + format.slice(1)}
              </button>
            ))}
          </div>
        </div>

        {/* Preview Section */}
        <div className="bg-gray-900 rounded-xl overflow-hidden">
          <div className="p-4 space-y-4">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-full bg-gradient-to-br from-blue-500 to-indigo-600 flex items-center justify-center text-white font-bold text-sm">
                {campaignPreview?.name?.[0] || 'A'}
              </div>
              <div>
                <div className="font-semibold text-gray-100">{campaignPreview?.name}</div>
                <div className="text-xs text-gray-400">Patrocinado</div>
              </div>
            </div>
            
            <p className="text-sm text-gray-300">
              {campaignPreview?.adContent?.descripcion}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

// **Componente CampaignDetails**
const CampaignDetails = ({ campaignPreview }) => (
  <div className="bg-gray-800 rounded-xl shadow-md border border-gray-700 p-4 mb-4">
    <div className="space-y-2">
      <div className="flex items-center gap-2">
        <div className={`w-2 h-2 rounded-full ${
          campaignPreview.status === 'active' ? 'bg-green-500' : 'bg-yellow-500'
        }`} />
        <span className="text-xs font-medium text-gray-300">
          {campaignPreview.status === 'active' ? 'Campaña Activa' : 'Borrador'}
        </span>
      </div>
      <h3 className="text-lg font-semibold text-gray-100">{campaignPreview.name}</h3>
      <div className="flex flex-wrap gap-3 text-sm text-gray-400">
        {campaignPreview.budget && (
          <span className="flex items-center gap-1">
            <DollarSign className="w-4 h-4" />
            ${campaignPreview.budget}/día
          </span>
        )}
        {campaignPreview.platform && (
          <span className="flex items-center gap-1">
            <Globe className="w-4 h-4" />
            {campaignPreview.platform}
          </span>
        )}
        {campaignPreview.objective && (
          <span className="flex items-center gap-1">
            <Target className="w-4 h-4" />
            {campaignPreview.objective}
          </span>
        )}
      </div>
    </div>
  </div>
);

// **Componente PreviewHeader**
const PreviewHeader = ({ deviceView, setDeviceView, activeTab, setActiveTab }) => (
  <div className="px-6 py-4 border-b border-gray-700 bg-gray-800 space-y-4">
    <div className="flex items-center justify-between">
      <h2 className="text-xl font-bold text-gray-100">Vista Previa</h2>
      <div className="flex gap-2">
        <button
          onClick={() => setDeviceView('desktop')}
          className={`px-4 py-2 rounded-xl flex items-center gap-2 text-sm font-medium transition-all ${
            deviceView === 'desktop' 
              ? 'bg-blue-900 text-blue-300 ring-1 ring-blue-500/30' 
              : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
          }`}
        >
          <Monitor className="h-4 w-4" />
          Desktop
        </button>
        <button
          onClick={() => setDeviceView('mobile')}
          className={`px-4 py-2 rounded-xl flex items-center gap-2 text-sm font-medium transition-all ${
            deviceView === 'mobile' 
              ? 'bg-blue-900 text-blue-300 ring-1 ring-blue-500/30' 
              : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
          }`}
        >
          <Smartphone className="h-4 w-4" />
          Mobile
        </button>
      </div>
    </div>
    
    <div className="flex gap-2">
      <button
        onClick={() => setActiveTab('google')}
        className={`flex-1 py-3 rounded-xl flex items-center justify-center gap-2 text-sm font-medium
          transition-all ${activeTab === 'google'
            ? 'bg-blue-900 text-blue-300 ring-1 ring-blue-500/30'
            : 'text-gray-300 hover:bg-gray-700'}`}
      >
        <Search className="h-4 w-4" />
        Google Ads
      </button>
      <button
        onClick={() => setActiveTab('meta')}
        className={`flex-1 py-3 rounded-xl flex items-center justify-center gap-2 text-sm font-medium
          transition-all ${activeTab === 'meta'
            ? 'bg-blue-900 text-blue-300 ring-1 ring-blue-500/30'
            : 'text-gray-300 hover:bg-gray-700'}`}
      >
        <Share2 className="h-4 w-4" />
        Meta Ads
      </button>
    </div>
  </div>
);

// **Componente EmptyPreview**
const EmptyPreview = () => (
  <div className="flex flex-col items-center justify-center h-full text-center px-6">
    <div className="h-16 w-16 rounded-2xl bg-blue-900/30 flex items-center justify-center mb-4">
      <Sparkles className="h-8 w-8 text-blue-400" />
    </div>
    <div className="max-w-sm">
      <h3 className="text-xl font-bold text-gray-100 mb-2">
        Tu vista previa aparecerá aquí
      </h3>
      <p className="text-sm text-gray-400">
        Describe tu campaña en el chat y te ayudaré a crear una vista previa profesional.
      </p>
    </div>
  </div>
);

// **Componente ConfirmationSwitch**
const ConfirmationSwitch = ({ onConfirm }) => {
  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleConfirm = () => {
    setIsConfirmed(!isConfirmed);
    if (!isConfirmed) {
      onConfirm();
    }
  };

  return (
    <div
      className={`relative w-14 h-7 rounded-full cursor-pointer transition-colors duration-300 
        ${isConfirmed ? 'bg-blue-600' : 'bg-gray-700'}`}
      onClick={handleConfirm}
    >
      <div
        className={`absolute w-5 h-5 bg-white rounded-full shadow-lg top-1 left-1 transition-transform duration-300
          flex items-center justify-center ${isConfirmed ? 'translate-x-7' : ''}`}
      >
        {isConfirmed && <Check className="w-3 h-3 text-blue-600" />}
      </div>
    </div>
  );
};

const CampaignPreviewSection = ({ 
  campaignPreview, 
  deviceView, 
  activeTab, 
  isCampaignReady, 
  onConfirm,
  onUpdateCampaign,
  onUploadImage,
  onUpdateSettings,
  setCampaignPreview,
  auth
}) => {
  // Agregar log para debugging
  useEffect(() => {
    console.log('Campaign Preview Updated:', campaignPreview);
  }, [campaignPreview]);

  // Validación más estricta
  if (!campaignPreview || !campaignPreview.name || !campaignPreview.platform) {
    console.log('Invalid campaign preview data:', campaignPreview);
    return <EmptyPreview />;
  }

  return (
    <div className={`transition-all duration-300 ${
      deviceView === 'mobile' ? 'max-w-sm' : 'max-w-2xl'
    } mx-auto`}>
      <CampaignDetails campaignPreview={campaignPreview} />
      {activeTab === 'google' ? (
        <GooglePreview campaignPreview={campaignPreview} />
      ) : (
        <MetaPreview
          campaignPreview={campaignPreview}
          onUpdateCampaign={onUpdateCampaign}
          onUploadImage={onUploadImage}
          onUpdateSettings={onUpdateSettings}
          auth={auth}
          setCampaignPreview={setCampaignPreview}
        />
      )}
      {isCampaignReady && (
        <div className="mt-6 flex justify-center items-center gap-4 bg-gray-800 p-4 rounded-xl shadow-md">
          <span className="text-sm font-medium text-gray-300">¿Confirmar campaña?</span>
          <ConfirmationSwitch onConfirm={onConfirm} />
        </div>
      )}
    </div>
  );
};

// **Componente Header**
const Header = ({ showPreview, setShowPreview, screenSize, onResetChat }) => (
  <div className="px-6 py-4 bg-gradient-to-r from-gray-900 to-gray-800">
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-4">
        <div className="h-12 w-12 rounded-2xl bg-blue-500/10 backdrop-blur-sm flex items-center justify-center">
          <Wand2 className="h-6 w-6 text-blue-400" />
        </div>
        <div>
          <h2 className="text-xl font-bold text-gray-100">CH3 </h2>
          <p className="text-sm text-gray-400">Asistente de Campañas</p>
        </div>
      </div>
      <button
        onClick={onResetChat}
        className="p-2 rounded-lg bg-gray-800 hover:bg-gray-700 text-gray-400 
          hover:text-white transition-colors"
        title="Nuevo chat"
      >
        <Plus className="h-5 w-5" />
      </button>
      {(screenSize.isMobile || screenSize.isTablet) && (
        <button
          onClick={() => setShowPreview(!showPreview)}
          className="h-10 w-10 rounded-xl hover:bg-gray-700 transition-colors flex items-center justify-center">
          {showPreview ? 
            <X className="h-5 w-5 text-gray-300" /> : 
            <Sparkles className="h-5 w-5 text-gray-300" />
          }
        </button>
      )}
    </div>
  </div>
);

const ChatInput = ({ inputValue, setInputValue, handleSendMessage, isCreatingCampaign }) => (
  <form onSubmit={handleSendMessage} className="p-4 border-t border-gray-700">
    <div className="flex items-center gap-3 bg-gray-800 rounded-2xl p-1.5">
      <input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        disabled={isCreatingCampaign}
        placeholder={isCreatingCampaign ? "Procesando mensaje..." : "Describe tu campaña aquí..."}
        className="flex-1 px-4 py-3 bg-transparent text-sm text-gray-100 
          placeholder-gray-500 focus:outline-none disabled:cursor-not-allowed
          transition-colors duration-200"
      />
      <button
        type="submit"
        disabled={isCreatingCampaign || !inputValue.trim()}
        className={`px-5 py-3 bg-gradient-to-r from-blue-600 to-indigo-600 
          text-white rounded-xl hover:from-blue-700 hover:to-indigo-700 
          focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 
          focus:ring-offset-gray-800 disabled:opacity-50 transition-all duration-200 
          flex items-center gap-2 min-w-[100px] justify-center`}
      >
        {isCreatingCampaign ? (
          <>
            <Loader className="h-4 w-4 animate-spin" />
            <span className="text-sm font-medium">Enviando</span>
          </>
        ) : (
          <>
            <Send className="h-4 w-4" />
            <span className="text-sm font-medium">Enviar</span>
          </>
        )}
      </button>
    </div>
    {isCreatingCampaign && (
      <div className="mt-2 text-xs text-gray-400 text-center">
        Esto puede tomar unos segundos...
      </div>
    )}
  </form>
);

const AICampaignCreation = ({ onCampaignCreated }) => {
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const chatId = params.get('chat');
  const chatRef = useRef(null);

  // Group all useState declarations together at the top
  const [inputValue, setInputValue] = useState('');
  const [isCreatingCampaign, setIsCreatingCampaign] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [campaignPreview, setCampaignPreview] = useState(null);
  const [isCampaignReady, setIsCampaignReady] = useState(false);
  const [deviceView, setDeviceView] = useState('desktop');
  const [activeTab, setActiveTab] = useState('google');
  const [showPreview, setShowPreview] = useState(true);
  const [conversationContext, setConversationContext] = useState({});
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [metaSettings, setMetaSettings] = useState(null);
  const [selectedPage, setSelectedPage] = useState('');
  const [selectedPixel, setSelectedPixel] = useState('');
  const [selectedCatalog, setSelectedCatalog] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [images, setImages] = useState({});
  const [selectedImageFormat, setSelectedImageFormat] = useState('feed');
  const [isSavingChat, setIsSavingChat] = useState(false);
  const [catalogs, setCatalogs] = useState([]);
  const [pages, setPages] = useState([]);
  const [pixels, setPixels] = useState([]);
  const [initialState] = useState({
    showOptions: true,
    welcomeMessage: {
      role: 'assistant',
      content: '👋 ¡Hola! Soy CH3, tu asistente de campañas publicitarias. ¿En qué puedo ayudarte hoy?',
      timestamp: new Date().toISOString()
    }
  });
  
  const [messages, setMessages] = useState([initialState.welcomeMessage]);
  const [showQuickOptions, setShowQuickOptions] = useState(initialState.showOptions);
  const [campaignDates, setCampaignDates] = useState({
    startDate: '',
    endDate: ''
  });
  const [screenSize, setScreenSize] = useState({
    isMobile: window.innerWidth < 640,
    isTablet: window.innerWidth >= 640 && window.innerWidth < 1024,
    isDesktop: window.innerWidth >= 1024
  });
  const initializeNewChat = useCallback(() => {
    const welcomeMessage = { 
      role: 'assistant', 
      content: '👋 ¡Hola! Soy CH3, tu asistente de campañas publicitarias. ¿En qué puedo ayudarte hoy?',
      timestamp: new Date().toISOString()
    };
    setMessages([welcomeMessage]);
    setCampaignPreview(null);
    setIsCampaignReady(false);
    setShowQuickOptions(true);
  }, []);
  // Now all useEffects can safely access any state variable

  useEffect(() => {
    const loadChatSession = async () => {
      if (!chatId) {
        setMessages([initialState.welcomeMessage]);
        setShowQuickOptions(true);
        return;
      }
  
      try {
        const response = await axios.get(
          `${API_URL}/api/chat/sessions/${chatId}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`
            }
          }
        );
        
        if (response.data.messages?.length > 0) {
          setMessages(response.data.messages);
          // Solo actualizamos showQuickOptions si es un chat nuevo
          if (response.data.messages.length === 1) {
            setShowQuickOptions(true);
          }
        } else {
          setMessages([initialState.welcomeMessage]);
          setShowQuickOptions(true);
        }
      } catch (error) {
        console.error('Error loading chat:', error);
        // En caso de error, mantenemos el estado inicial
        setMessages([initialState.welcomeMessage]);
        setShowQuickOptions(true);
      }
    };
  
    loadChatSession();
  }, [chatId, auth.token, initialState]);

  const QuickOptions = ({ onSelect }) => {
    const options = [
      {
        id: 1,
        text: "Hola! Quiero crear una campaña. me ayudas?",
        icon: Plus,
        title: "Crear una campaña",
        description: "Te ayudo a crear una campaña personalizada"
      },
      {
        id: 2,
        text: "Hola! Quiero saber las estadisticas de mis campañas",
        icon: BarChart2,
        title: "Ver estadísticas",
        description: "Revisa el rendimiento de tus campañas"
      },
      {
        id: 3,
        text: "Hola! Quiero saber cual es el Roi de mis campañas",
        icon: DollarSign,
        title: "Ver ROI",
        description: "Analiza el retorno de inversión"
      },
      {
        id: 4,
        text: "Hola! Quiero que me respondas ciertas dudas de mis campañas",
        icon: HelpCircle,
        title: "Resolver dudas",
        description: "Respondo tus preguntas sobre campañas"
      }
    ];
  
    return (
      <div className="sticky top-0 z-10 bg-gray-900/95 backdrop-blur-sm border-b border-gray-800 mb-4">
        <div className="max-w-3xl mx-auto px-4 py-3">
          <div className="grid grid-cols-2 gap-3">
            {options.map((option) => {
              const Icon = option.icon;
              return (
                <button
                  key={option.id}
                  onClick={() => onSelect(option.text)}
                  className="p-4 bg-gray-800/50 rounded-xl hover:bg-gray-700/80 transition-all duration-200
                    text-left group border border-gray-700 hover:border-blue-500/50"
                >
                  <div className="flex items-center gap-3">
                    <div className={`h-10 w-10 rounded-lg flex items-center justify-center
                      ${option.id === 1 ? 'bg-blue-500/10' : 
                        option.id === 2 ? 'bg-green-500/10' : 
                        option.id === 3 ? 'bg-indigo-500/10' : 
                        'bg-purple-500/10'}`}
                    >
                      <Icon className={`h-5 w-5 
                        ${option.id === 1 ? 'text-blue-400' : 
                          option.id === 2 ? 'text-green-400' : 
                          option.id === 3 ? 'text-indigo-400' : 
                          'text-purple-400'}`} 
                      />
                    </div>
                    <div className="flex-1">
                      <span className="text-base font-medium text-gray-200 group-hover:text-white">
                        {option.title}
                      </span>
                      <p className="text-sm text-gray-400 mt-1">
                        {option.description}
                      </p>
                    </div>
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const handleQuickOptionSelect = (text) => {
    // Desactivamos las opciones inmediatamente para evitar parpadeos
    setShowQuickOptions(false);
    
    const event = {
      preventDefault: () => {}
    };
    
    setInputValue(text);
    // Pequeño delay para asegurar que el estado se actualice correctamente
    setTimeout(() => {
      handleSendMessage(event);
    }, 0);
  };
useEffect(() => {
  if (chatId) {
    // Load existing chat session
    const loadSession = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/chat/sessions/${chatId}`, {
          headers: {
            Authorization: `Bearer ${auth.token}`
          }
        });
        setMessages(response.data.messages);
      } catch (error) {
        console.error('Error loading chat session:', error);
      }
    };
    loadSession();
  }
}, [chatId, auth.token]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setScreenSize({
        isMobile: width < 640,
        isTablet: width >= 640 && width < 1024,
        isDesktop: width >= 1024
      });
      if (width >= 1024) {
        setShowPreview(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const handleResetChat = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/api/chat/sessions`,
        {
          title: `Chat ${new Date().toLocaleString()}`
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`
          }
        }
      );
      
      navigate(`/dashboard?chat=${response.data.id}`);
      initializeNewChat();
      setCampaignPreview(null);
      setIsCampaignReady(false);
    } catch (error) {
      console.error('Error creating new chat:', error);
    }
  };
  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages]);
 
  useEffect(() => {
    // Cargar configuración de Meta si es necesario
    const loadMetaConfiguration = async () => {
      if (auth.user?.meta_ad_account_id) {
        try {
          const response = await axios.get(`${API_URL}/api/meta/account-settings/${auth.user.meta_ad_account_id}`, {
            headers: { Authorization: `Bearer ${auth.token}` }
          });
          setMetaSettings(response.data);
        } catch (error) {
          console.error('Error loading Meta settings:', error);
        }
      }
    };

    loadMetaConfiguration();
  }, [auth.user?.meta_ad_account_id, auth.token]);

  useEffect(() => {
    if (campaignPreview?.metaSettings) {
      setSelectedPage(campaignPreview.metaSettings.page_id || '');
      setSelectedPixel(campaignPreview.metaSettings.pixel_id || '');
      setSelectedCatalog(campaignPreview.metaSettings.catalog_id || '');
      
      // Set initial dates
      if (campaignPreview.metaSettings.campaignDates) {
        setCampaignDates({
          startDate: campaignPreview.metaSettings.campaignDates.startDate || '',
          endDate: campaignPreview.metaSettings.campaignDates.endDate || ''
        });
      }
    }
  }, [campaignPreview?.metaSettings]);

  useEffect(() => {
    const loadMetaData = async () => {
      try {
        console.log('Token disponible:', auth?.token); // Debug
        
        const headers = { Authorization: `Bearer ${auth.token}` };
        
        // Cargar páginas
        const pagesResponse = await axios.get(
          `${API_URL}/api/meta/pages`,
          { headers }
        );
        console.log('Páginas cargadas:', pagesResponse.data); // Debug
        setPages(pagesResponse.data);
  
        // Cargar píxeles
        const pixelsResponse = await axios.get(
          `${API_URL}/api/meta/pixels`,
          { headers }
        );
        console.log('Píxeles cargados:', pixelsResponse.data); // Debug
        setPixels(pixelsResponse.data);
  
        // Cargar catálogos
        const catalogsResponse = await axios.get(
          `${API_URL}/api/meta/catalogs`,
          { headers }
        );
        console.log('Catálogos cargados:', catalogsResponse.data); // Debug
        setCatalogs(catalogsResponse.data);
  
      } catch (error) {
        console.error('Error detallado:', {
          message: error.message,
          response: error.response?.data,
          status: error.response?.status
        });
      }
    };
  
    if (auth?.token) {
      loadMetaData();
    }
  }, [auth?.token]);

  const handleCampaignUpdate = (updatedCampaign) => {
    setCampaignPreview(updatedCampaign);
    // Si hay metaSettings, asegúrate de incluirlos
    if (metaSettings && updatedCampaign.platform?.toLowerCase().includes('meta')) {
      setCampaignPreview({
        ...updatedCampaign,
        metaSettings: {
          ...metaSettings,
          ...(updatedCampaign.metaSettings || {})
        }
      });
    }
  };

  // Función para manejar la actualización de configuraciones
  const handleSettingsUpdate = (newSettings) => {
    setCampaignPreview(prev => ({
      ...prev,
      metaSettings: {
        ...(prev?.metaSettings || {}),
        ...newSettings
      }
    }));
  };

  const handleImageUploadLocal = async (file) => {
    if (!file) return;
    
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append('image', file);
      
      // Subir la imagen al servidor
      const response = await axios.post(`${API_URL}/api/meta/upload-image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${auth.token}`
        }
      });
  
      if (response.data.url) {
        // Actualizar el estado local de imágenes
        setImages(prev => ({
          ...prev,
          [selectedImageFormat]: response.data.url
        }));
  
        // Actualizar el preview de la campaña
        setCampaignPreview(prev => ({
          ...prev,
          adContent: {
            ...prev.adContent,
            image_urls: {
              ...(prev.adContent?.image_urls || {}),
              [selectedImageFormat]: response.data.url
            }
          }
        }));
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      alert('Error al subir la imagen. Por favor, intenta de nuevo.');
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    // Obtener la cuenta activa del usuario cuando se carga el componente
    if (auth.user?.google_customer_id) {
      setSelectedCustomerId(auth.user.google_customer_id);
    }
  }, [auth.user]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!inputValue.trim() || isCreatingCampaign) return;
    
    // Desactivar las opciones al inicio del envío
    setShowQuickOptions(false);
    
    const newUserMessage = { 
      role: 'user', 
      content: inputValue,
      timestamp: new Date().toISOString()
    };
  
    setMessages(prev => [...prev, newUserMessage]);
    setInputValue('');
    setIsCreatingCampaign(true);
    setIsTyping(true);
    
    try {
      let currentChatId = chatId;
      
      // Crear nueva sesión si no existe
      if (!currentChatId) {
        const sessionResponse = await axios.post(
          `${API_URL}/api/chat/sessions`,
          {
            title: `Chat ${new Date().toLocaleString()}`
          },
          {
            headers: {
              Authorization: `Bearer ${auth.token}`
            }
          }
        );
        currentChatId = sessionResponse.data.id;
        navigate(`/dashboard?chat=${currentChatId}`);
      }
  
      // Guardar mensaje del usuario
      await axios.post(
        `${API_URL}/api/chat/sessions/${currentChatId}/messages`,
        newUserMessage,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`
          }
        }
      );
  
      // Obtener respuesta del asistente
      const aiResponse = await axios.post(
        `${API_URL}/create_campaign_conversation`,
        { 
          message: inputValue,
          session_id: currentChatId,
          campaign_preview: campaignPreview
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`
          }
        }
      );
  
      // Procesar la respuesta
      let previewData = null;
      if (aiResponse.data.campaign_details) {
        previewData = {
          name: aiResponse.data.campaign_details.nombre,
          platform: aiResponse.data.campaign_details.plataforma,
          budget: aiResponse.data.campaign_details.presupuesto,
          objective: aiResponse.data.campaign_details.objetivo,
          status: 'draft',
          adContent: aiResponse.data.ad_content || {},
          metaSettings: {
            campaignDates: aiResponse.data.metaSettings?.campaignDates || {
              startDate: new Date().toISOString().split('T')[0],
              endDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
            }
          }
        };
  
        // Actualizar el preview
        setCampaignPreview(previewData);
        setIsCampaignReady(true);
      }
  
      // Crear mensaje del asistente
      const assistantMessage = {
        role: 'assistant',
        content: aiResponse.data.message,
        timestamp: new Date().toISOString(),
        campaignPreview: previewData
      };
  
      // Actualizar mensajes en el estado
      setMessages(prev => [...prev, assistantMessage]);
  
      // Guardar mensaje del asistente
      await axios.post(
        `${API_URL}/api/chat/sessions/${currentChatId}/messages`,
        assistantMessage,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`
          }
        }
      );
  
      // Si la respuesta incluye una recomendación de campaña, actualizar el contexto
      if (aiResponse.data.campaign_details) {
        setConversationContext(prev => ({
          ...prev,
          lastCampaign: aiResponse.data.campaign_details
        }));
      }
  
    } catch (error) {
      console.error('Error:', error);
      setMessages(prev => [...prev, {
        role: 'system',
        content: '❌ Error al procesar el mensaje. Por favor, intenta de nuevo.',
        timestamp: new Date().toISOString()
      }]);
    } finally {
      setIsCreatingCampaign(false);
      setIsTyping(false);
    }
  };
  
  // **Función para manejar la publicación de la campaña**
  const handlePublishCampaign = async () => {
    setIsCreatingCampaign(true);
    try {
      const campaignData = {
        campaign_details: {
          ...campaignPreview,
          customer_id: selectedCustomerId,
          // Agregar fechas de campaña
          start_date: campaignPreview.metaSettings?.campaignDates?.startDate,
          end_date: campaignPreview.metaSettings?.campaignDates?.endDate
        },
        context: conversationContext
      };

      const response = await axios.post(
        `${API_URL}/create_campaign`,
        campaignData,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.token}`
          }
        }
      );

      const data = response.data;

      setMessages(prev => [
        ...prev,
        {
          role: 'system',
          content: '✅ ¡Tu campaña ha sido creada exitosamente!',
          timestamp: new Date().toISOString()
        }
      ]);

      setIsCampaignReady(false);
      setCampaignPreview(null);
      setConversationContext({});

      onCampaignCreated?.(data.new_campaign);

    } catch (error) {
      console.error('Error:', error);
      setMessages(prev => [
        ...prev,
        {
          role: 'system',
          content: '❌ Hubo un error al crear tu campaña. ¿Intentamos de nuevo?',
          type: 'error',
          timestamp: new Date().toISOString()
        }
      ]);
    } finally {
      setIsCreatingCampaign(false);
    }
  };
// Agregar esta función dentro del componente AICampaignCreation
const handleImageUpload = async (formData, format) => {
  setIsUploading(true);
  try {
    const response = await axios.post(
      `${API_URL}/api/meta/upload-image`,
      formData,
      {
        headers: { 
          'Authorization': `Bearer ${auth.token}`,
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    if (response.data.url) {
      // Actualizar imágenes locales
      setImages(prev => ({
        ...prev,
        [format]: response.data.url
      }));

      // Actualizar el preview de la campaña
      setCampaignPreview(prev => ({
        ...prev,
        adContent: {
          ...prev.adContent,
          image_urls: {
            ...(prev.adContent?.image_urls || {}),
            [format]: response.data.url
          }
        }
      }));
    }
  } catch (error) {
    console.error('Error uploading image:', error);
    alert('Error al subir la imagen. Por favor, intenta de nuevo.');
  } finally {
    setIsUploading(false);
  }
};
return (
  <div className="h-full flex flex-col lg:flex-row bg-gray-900">
    {/* Sección del Chat */}
    <div className={`${showPreview ? 'lg:w-1/2' : 'w-full'} flex flex-col h-full bg-gray-900 shadow-xl border-r border-gray-700`}>
      <Header 
        showPreview={showPreview} 
        setShowPreview={setShowPreview} 
        screenSize={screenSize}
        onResetChat={handleResetChat}
      />

      <div ref={chatRef} className="flex-1 overflow-y-auto px-6 py-4 scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-900">
        {/* Mensajes del chat */}
        {messages.map((message, index) => (
          <Message key={index} message={message} />
        ))}
        
        {/* Indicador de escritura */}
        {isTyping && (
          <Message message={{ role: 'assistant', content: '' }} isTyping={true} />
        )}
        
        {/* Opciones rápidas */}
        {showQuickOptions && messages.length === 1 && messages[0].role === 'assistant' && !isTyping && !isCreatingCampaign && (
          <div className="sticky top-0 z-10 bg-gray-900/95 backdrop-blur-sm mb-4">
            <div className="mt-8 mb-4">
              <div className="grid grid-cols-2 gap-3 max-w-3xl mx-auto">
                <button
                  onClick={() => handleQuickOptionSelect("Hola! Quiero crear una campaña. me ayudas?")}
                  className="p-6 bg-gray-800/50 backdrop-blur-sm rounded-xl hover:bg-gray-700/80 transition-all duration-200
                    text-left group border border-gray-700 hover:border-blue-500/50"
                >
                  <div className="flex items-center gap-3">
                    <div className="h-10 w-10 rounded-lg bg-blue-500/10 flex items-center justify-center">
                      <Plus className="h-5 w-5 text-blue-400" />
                    </div>
                    <div className="flex-1">
                      <span className="text-base font-medium text-gray-200 group-hover:text-white">
                        Crear una campaña
                      </span>
                      <p className="text-sm text-gray-400 mt-1">
                        Te ayudo a crear una campaña personalizada
                      </p>
                    </div>
                  </div>
                </button>

                <button
                  onClick={() => handleQuickOptionSelect("Hola! Quiero saber las estadisticas de mis campañas")}
                  className="p-6 bg-gray-800/50 backdrop-blur-sm rounded-xl hover:bg-gray-700/80 transition-all duration-200
                    text-left group border border-gray-700 hover:border-blue-500/50"
                >
                  <div className="flex items-center gap-3">
                    <div className="h-10 w-10 rounded-lg bg-green-500/10 flex items-center justify-center">
                      <BarChart2 className="h-5 w-5 text-green-400" />
                    </div>
                    <div className="flex-1">
                      <span className="text-base font-medium text-gray-200 group-hover:text-white">
                        Ver estadísticas
                      </span>
                      <p className="text-sm text-gray-400 mt-1">
                        Revisa el rendimiento de tus campañas
                      </p>
                    </div>
                  </div>
                </button>

                <button
                  onClick={() => handleQuickOptionSelect("Hola! Quiero saber cual es el Roi de mis campañas")}
                  className="p-6 bg-gray-800/50 backdrop-blur-sm rounded-xl hover:bg-gray-700/80 transition-all duration-200
                    text-left group border border-gray-700 hover:border-blue-500/50"
                >
                  <div className="flex items-center gap-3">
                    <div className="h-10 w-10 rounded-lg bg-indigo-500/10 flex items-center justify-center">
                      <DollarSign className="h-5 w-5 text-indigo-400" />
                    </div>
                    <div className="flex-1">
                      <span className="text-base font-medium text-gray-200 group-hover:text-white">
                        Ver ROI
                      </span>
                      <p className="text-sm text-gray-400 mt-1">
                        Analiza el retorno de inversión
                      </p>
                    </div>
                  </div>
                </button>

                <button
                  onClick={() => handleQuickOptionSelect("Hola! Quiero que me respondas ciertas dudas de mis campañas")}
                  className="p-6 bg-gray-800/50 backdrop-blur-sm rounded-xl hover:bg-gray-700/80 transition-all duration-200
                    text-left group border border-gray-700 hover:border-blue-500/50"
                >
                  <div className="flex items-center gap-3">
                    <div className="h-10 w-10 rounded-lg bg-purple-500/10 flex items-center justify-center">
                      <HelpCircle className="h-5 w-5 text-purple-400" />
                    </div>
                    <div className="flex-1">
                      <span className="text-base font-medium text-gray-200 group-hover:text-white">
                        Resolver dudas
                      </span>
                      <p className="text-sm text-gray-400 mt-1">
                        Respondo tus preguntas sobre campañas
                      </p>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <ChatInput 
        inputValue={inputValue}
        setInputValue={setInputValue}
        handleSendMessage={handleSendMessage}
        isCreatingCampaign={isCreatingCampaign}
      />
    </div>

    {/* Sección de la Vista Previa */}
    {showPreview && (
      <div className="w-full lg:w-1/2 h-full flex flex-col bg-gray-900 shadow-xl">
        <PreviewHeader 
          deviceView={deviceView}
          setDeviceView={setDeviceView}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className="flex-1 overflow-y-auto p-6 scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-900">
          {campaignPreview ? (
            <CampaignPreviewSection 
              campaignPreview={campaignPreview}
              deviceView={deviceView}
              activeTab={activeTab}
              isCampaignReady={isCampaignReady}
              onConfirm={handlePublishCampaign}
              onUpdateCampaign={handleCampaignUpdate}
              onUploadImage={handleImageUpload}
              onUpdateSettings={handleSettingsUpdate}
              setCampaignPreview={setCampaignPreview}
              auth={auth}
            />
          ) : (
            <EmptyPreview />
          )}
        </div>
      </div>
    )}
  </div>
);
};

export default AICampaignCreation;
