import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../components/context/AuthContext';
import { RefreshCw, AlertCircle } from 'lucide-react';
import axios from 'axios';

const GoogleCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth, updateUser } = useAuth();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const params = new URLSearchParams(location.search);
        const code = params.get('code');
        const state = params.get('state');
        
        // Verify CSRF state
        const savedState = localStorage.getItem('google_auth_state');
        localStorage.removeItem('google_auth_state');
        
        if (state !== savedState) {
          throw new Error('Estado inválido');
        }

        if (!code) {
          throw new Error('No se recibió código de autorización');
        }

        // Store current Meta connection state
        const hasMetaConnection = auth.user?.meta_connected;
        const metaAccountId = auth.user?.meta_ad_account_id;

        // Send code to backend
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/google/callback`,
          { code },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.token}`
            }
          }
        );

        if (!response.data) {
          throw new Error('Respuesta inválida del servidor');
        }

        // Update user state while preserving Meta connection
        const updatedUserData = {
          ...auth.user,
          google_connected: true,
          google_customer_id: response.data.google_customer_id || null,
          // Preserve Meta connection if it existed
          meta_connected: hasMetaConnection,
          meta_ad_account_id: metaAccountId
        };

        await updateUser(updatedUserData);

        // Redirect to accounts page instead of dashboard
        navigate('/accounts', {
          replace: true,
          state: { 
            googleConnected: true,
            customerId: response.data.google_customer_id,
            metaPreserved: hasMetaConnection
          }
        });
      } catch (error) {
        console.error('Error en callback:', error);
        const errorMessage = error.response?.data?.error || error.message || 'Error al procesar la autenticación';
        setError(errorMessage);
        
        setTimeout(() => {
          navigate('/accounts', {
            replace: true,
            state: { error: errorMessage }
          });
        }, 3000);
      } finally {
        setLoading(false);
      }
    };

    if (location.search.includes('code=')) {
      handleCallback();
    } else {
      navigate('/accounts', { replace: true });
    }
  }, [navigate, location, auth.token, auth.user, updateUser]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900">
        <div className="bg-gray-800 p-6 rounded-lg shadow-xl border border-gray-700">
          <div className="flex flex-col items-center">
            <RefreshCw className="h-12 w-12 text-blue-500 animate-spin mb-4" />
            <h3 className="text-lg font-medium text-gray-100">
              Conectando con Google Ads
            </h3>
            <p className="text-gray-400 text-center mt-2">
              Manteniendo conexión existente con Meta Ads...
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900">
        <div className="bg-gray-800 p-6 rounded-lg shadow-xl border border-gray-700">
          <div className="text-red-400 mb-4">
            <AlertCircle className="h-6 w-6 mx-auto mb-2" />
            <h3 className="text-lg font-medium text-center">
              Error de Autenticación
            </h3>
          </div>
          <p className="text-gray-400 text-center">{error}</p>
          <p className="text-gray-500 text-sm text-center mt-2">
            Redirigiendo a la página de cuentas...
          </p>
        </div>
      </div>
    );
  }

  return null;
};

export default GoogleCallback;