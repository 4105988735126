import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../components/context/AuthContext';
import axios from 'axios';
import { Alert, Spinner } from 'react-bootstrap';
const redirect_uri = 'https://app.ch3.ai/auth/facebook/callback';
const API_URL = process.env.REACT_APP_API_URL;

const FacebookCallback = () => {
  const navigate = useNavigate();
  const { login, updateUser } = useAuth();
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(true);
  const [status, setStatus] = useState('Iniciando verificación...');

  useEffect(() => {
    const handleFacebookCallback = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        const state = params.get('state');
        const errorParam = params.get('error');

        // Verificar estado CSRF
        const savedState = localStorage.getItem('facebook_auth_state');
        localStorage.removeItem('facebook_auth_state');

        if (state !== savedState) {
          throw new Error('Estado inválido - posible ataque CSRF');
        }

        if (errorParam) {
          throw new Error(params.get('error_description') || 'Error de autorización');
        }

        if (!code) {
          throw new Error('No se recibió código de autorización');
        }

        setStatus('Verificando autorización...');

        // Enviar solicitud al backend
        const response = await axios.post(`${API_URL}/facebook/verify`, {
          code,
          redirect_uri: `${window.location.origin}/facebook-callback`
        });

        if (!response.data.access_token) {
          throw new Error('No se recibió token de acceso');
        }

        setStatus('Obteniendo información de la cuenta...');

        // Actualizar el estado del usuario con la información de Meta
        if (response.data.user) {
          await updateUser({
            ...response.data.user,
            meta_connected: true,
            meta_ad_account_id: response.data.user.meta_ad_account_id
          });
        }

        // Login y redirección
        await login(response.data.access_token);
        
        setStatus('Conexión exitosa. Redirigiendo...');
        
        // Redireccionar con parámetros de éxito
        navigate('/accounts', { 
          state: { 
            metaConnected: true,
            message: 'Cuenta de Meta Ads conectada exitosamente'
          }
        });

      } catch (err) {
        console.error('Facebook callback error:', err);
        setError(err.response?.data?.error || err.message || 'Error de autenticación');
      } finally {
        setIsProcessing(false);
      }
    };

    handleFacebookCallback();
  }, [navigate, login, updateUser]);

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center p-4 bg-gray-50">
        <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
          <div className="flex items-center justify-center text-red-600 mb-4">
            <svg
              className="w-12 h-12"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          <h2 className="text-xl font-semibold text-center mb-4">
            Error de conexión
          </h2>
          <p className="text-gray-600 text-center mb-6">{error}</p>
          <div className="flex justify-center">
            <button
              onClick={() => navigate('/accounts')}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
            >
              Volver a intentar
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
        <div className="flex flex-col items-center">
          <div className="mb-4">
            {isProcessing && (
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600" />
            )}
          </div>
          <h2 className="text-xl font-semibold mb-4">
            Conectando con Meta Ads
          </h2>
          <p className="text-gray-600 text-center">{status}</p>
        </div>
      </div>
    </div>
  );
};

export default FacebookCallback;
