import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AuthContext } from '../components/context/AuthContext';
import { 
  MessageSquare, 
  BarChart2, 
  Link as LinkIcon,  
  LogOut, 
  Menu, 
  ChevronRight, 
  User, 
  Pin, 
  X, 
  ChevronDown,
  RefreshCw,
  Settings,
  Plus,
  Archive
} from 'lucide-react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const Sidebar = ({ handleLogout, onExpandedChange }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useContext(AuthContext);

  // UI States
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [expandedSection, setExpandedSection] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [isLoadingSessions, setIsLoadingSessions] = useState(false);

  const [screenSize, setScreenSize] = useState({
    isMobile: false,
    isTablet: false,
    isDesktop: true
  });

  // Chat Sessions Management
  const fetchChatSessions = async () => {
    setIsLoadingSessions(true);
    try {
      const response = await axios.get(`${API_URL}/api/chat/sessions`, {
        headers: {
          Authorization: `Bearer ${auth.token}`
        }
      });
      setSessions(response.data);
    } catch (error) {
      console.error('Error fetching chat sessions:', error);
    } finally {
      setIsLoadingSessions(false);
    }
  };

  const createNewSession = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/api/chat/sessions`,
        {
          title: `Chat ${new Date().toLocaleString()}`
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`
          }
        }
      );
      setSessions(prev => [response.data, ...prev]);
      navigate(`/dashboard?chat=${response.data.id}`);
    } catch (error) {
      console.error('Error creating new session:', error);
    }
  };

  const archiveSession = async (sessionId) => {
    try {
      await axios.delete(
        `${API_URL}/api/chat/sessions/${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`
          }
        }
      );
      setSessions(prev => prev.filter(s => s.id !== sessionId));
    } catch (error) {
      console.error('Error archiving session:', error);
    }
  };

  useEffect(() => {
    fetchChatSessions();
  }, [auth.token]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setScreenSize({
        isMobile: width < 640,
        isTablet: width >= 640 && width < 1024,
        isDesktop: width >= 1024
      });
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (screenSize.isMobile || screenSize.isTablet) {
      setIsMobileOpen(false);
      setShowProfileMenu(false);
    }
  }, [location.pathname, screenSize.isMobile, screenSize.isTablet]);

  const handleLogoutClick = useCallback(() => {
    setShowProfileMenu(false);
    handleLogout();
  }, [handleLogout]);

  useEffect(() => {
    if (screenSize.isDesktop) {
      onExpandedChange?.(isPinned || isHovered);
    }
  }, [isPinned, isHovered, screenSize.isDesktop, onExpandedChange]);

  // Navigation menu items
  const menuItems = [
    { 
      icon: MessageSquare, 
      label: 'Chat IA', 
      path: '/dashboard',
      description: 'Asistente de Marketing'
    },
    { 
      icon: BarChart2, 
      label: 'Analytics', 
      path: '/analytics',
      description: 'Análisis de campañas'
    },
    { 
      icon: LinkIcon, 
      label: 'Conexiones', 
      path: '/accounts',
      description: 'Gestión de cuentas'
    }
  ];

  const isExpanded = screenSize.isDesktop ? (isPinned || isHovered) : isMobileOpen;

  // Backdrop for mobile
  const Backdrop = () => (
    <div 
      className={`fixed inset-0 bg-black/50 transition-opacity z-30 backdrop-blur-[1px]
        ${isExpanded && (screenSize.isMobile || screenSize.isTablet) ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
      onClick={() => setIsMobileOpen(false)}
    />
  );

  return (
    <>
      <Backdrop />
      
      {/* Mobile Toggle Button */}
      <button 
        className="lg:hidden fixed top-4 left-4 z-[60] p-2.5 rounded-xl bg-white/95 shadow-lg
          hover:bg-gray-50 active:bg-gray-100 transition-all duration-200 backdrop-blur-sm
          ring-1 ring-gray-200"
        onClick={() => setIsMobileOpen(!isMobileOpen)}
      >
        {isMobileOpen ? 
          <X size={22} className="text-gray-700" /> : 
          <Menu size={22} className="text-gray-700" />
        }
      </button>

      {/* Sidebar Container */}
      <aside
        className={`fixed inset-y-0 left-0 z-50 transition-all duration-300 ease-in-out
          ${screenSize.isMobile || screenSize.isTablet 
            ? `${isExpanded ? 'translate-x-0 w-[280px]' : '-translate-x-full w-0'}` 
            : `${isExpanded ? 'w-64' : 'w-16'} transform-none`}
          ${isExpanded ? 'shadow-xl' : 'shadow-md'}`}
        onMouseEnter={() => screenSize.isDesktop && setIsHovered(true)}
        onMouseLeave={() => screenSize.isDesktop && setIsHovered(false)}
      >
        <div className="flex flex-col h-full bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 
          overflow-hidden relative">
          {/* Decorative effects */}
          <div className="absolute inset-x-0 top-0 h-px bg-gradient-to-r from-transparent via-gray-500/20 to-transparent" />
          <div className="absolute inset-x-0 bottom-0 h-px bg-gradient-to-r from-transparent via-gray-500/20 to-transparent" />
          
          {/* Header Section */}
          <div className="p-4 border-b border-gray-700/50 relative">
            <div className="flex items-center justify-between">
              <div className="flex items-center min-w-0">
                {isExpanded ? (
                  <div className="relative flex items-center justify-center w-8 h-8">
                    <img src="/ch3.png" alt="CH3" className="h-8 relative z-10" />
                    <div className="absolute inset-0 bg-blue-500/10 blur-xl rounded-lg" />
                  </div>
                ) : (
                  <div className="relative flex items-center justify-center w-8 h-8">
                    <div className="absolute inset-0 bg-gradient-to-br from-blue-600/20 to-indigo-600/20 
                      rounded-lg backdrop-blur-sm ring-1 ring-white/10" />
                    <span className="relative z-10 text-sm font-semibold text-white/90 group-hover:text-white 
                      transition-colors duration-200">
                      CH3
                    </span>
                  </div>
                )}
              </div>

              {screenSize.isDesktop && (
                <button
                  onClick={() => setIsPinned(!isPinned)}
                  className={`p-1.5 rounded-lg transition-all duration-300
                    ${isPinned 
                      ? 'bg-blue-500/20 text-blue-400 ring-1 ring-blue-400/30' 
                      : 'text-gray-400 hover:bg-gray-700/80 hover:text-white'}
                    ${isExpanded ? 'opacity-100' : 'opacity-0 w-0 overflow-hidden'}`}
                >
                  <Pin size={16} className={`transition-transform duration-300 
                    ${isPinned ? 'rotate-45' : 'hover:rotate-45'}`} />
                </button>
              )}
            </div>
          </div>

          {/* Navigation and Content */}
          <div className="flex-1 flex flex-col min-h-0 overflow-y-auto">
            {/* Main Navigation */}
            <nav className="px-2 space-y-1 py-2">
              {menuItems.map((item) => (
                <button
                  key={item.path}
                  onClick={() => {
                    navigate(item.path);
                    if (screenSize.isMobile || screenSize.isTablet) {
                      setIsMobileOpen(false);
                    }
                  }}
                  className={`relative group flex items-center w-full rounded-lg transition-all duration-200
                    ${location.pathname === item.path 
                      ? 'text-blue-400 bg-blue-500/10' 
                      : 'text-gray-400 hover:text-white hover:bg-gray-700/50'}
                    ${isExpanded ? 'px-3 py-2.5' : 'px-2 py-2.5 justify-center'}`}
                >
                  {location.pathname === item.path && (
                    <div className="absolute inset-y-1 left-0 w-1 bg-blue-500 rounded-r-full" />
                  )}
                  
                  <item.icon size={18} className={`flex-shrink-0 transition-transform duration-200
                    ${!isExpanded && 'group-hover:scale-110'}`} />
                  
                  <div className={`ml-3 text-left transition-all duration-300 min-w-0
                    ${isExpanded ? 'opacity-100' : 'opacity-0 w-0 overflow-hidden'}`}>
                    <div className="font-medium truncate">{item.label}</div>
                    <div className="text-xs text-gray-500 truncate">{item.description}</div>
                  </div>
                  
                  {!isExpanded && (
                    <div className="absolute left-full ml-3 px-2 py-1 bg-gray-800 text-white text-xs
                      rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200
                      pointer-events-none whitespace-nowrap shadow-lg">
                      {item.label}
                    </div>
                  )}
                </button>
              ))}
            </nav>

            {/* Chat Sessions Section */}
            {isExpanded && (
              <div className="px-2 py-4">
                <div className="flex items-center justify-between px-3 mb-2">
                  <span className="text-sm font-medium text-gray-400">Chats</span>
                  <div className="flex items-center gap-1">
                    <button
                      onClick={fetchChatSessions}
                      className="p-1 rounded-lg text-gray-400 hover:text-white hover:bg-gray-700/50 transition-colors"
                    >
                      <RefreshCw size={14} />
                    </button>
                    <button
                      onClick={createNewSession}
                      className="p-1 rounded-lg text-gray-400 hover:text-white hover:bg-gray-700/50 transition-colors"
                    >
                      <Plus size={14} />
                    </button>
                  </div>
                </div>

                {isLoadingSessions ? (
                  <div className="flex justify-center py-4">
                    <RefreshCw className="animate-spin text-blue-500" size={18} />
                  </div>
                ) : (
                  <div className="space-y-1">
                    {sessions.map((session) => (
                      <div
                        key={session.id}
                        className="group flex items-center gap-2 px-3 py-2 rounded-lg hover:bg-gray-700/50 
                          transition-colors cursor-pointer"
                        onClick={() => navigate(`/dashboard?chat=${session.id}`)}
                      >
                        <div className="flex-1 min-w-0">
                          <div className="text-sm font-medium text-gray-300 truncate">
                            {session.title}
                          </div>
                          {session.last_message && (
                            <div className="text-xs text-gray-500 truncate">
                              {session.last_message}
                            </div>
                          )}
                        </div>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            archiveSession(session.id);
                          }}
                          className="opacity-0 group-hover:opacity-100 p-1 text-gray-400 
                            hover:text-red-400 transition-all"
                        >
                          <Archive size={14} />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Profile and Logout Section */}
          <div className="mt-auto border-t border-gray-700/50">
            <div className="relative">
              <button
                onClick={() => setShowProfileMenu(!showProfileMenu)}
                className={`flex items-center w-full px-4 py-3 text-gray-300 hover:text-white 
                  hover:bg-gray-700/50 transition-colors duration-200`}
              >
                <div className="flex items-center flex-1 min-w-0">
                  <div className="relative flex-shrink-0">
                    <div className="h-8 w-8 rounded-full bg-blue-500/20 flex items-center justify-center
                      ring-2 ring-blue-500/30">
                      <span className="text-sm font-medium text-blue-400">
                        {auth.user?.name?.[0] || 'U'}
                      </span>
                    </div>
                    <div className="absolute bottom-0 right-0 h-2.5 w-2.5 bg-green-500 rounded-full
                      ring-2 ring-gray-900" />
                  </div>
                  {isExpanded && (
                    <div className="ml-3 flex-1 min-w-0">
                      <p className="text-sm font-medium truncate">
                        {auth.user?.name || 'Usuario'}
                      </p>
                      <p className="text-xs text-gray-500 truncate">
                        {auth.user?.email}
                      </p>
                    </div>
                  )}
                </div>
                {isExpanded && (
                  <ChevronDown
                    size={16}
                    className={`ml-2 transform transition-transform duration-200
                      ${showProfileMenu ? 'rotate-180' : ''}`}
                  />
                )}
              </button>

              {/* Profile Menu Dropdown */}
              {showProfileMenu && isExpanded && (
                <div className="absolute bottom-full left-0 right-0 mb-1 bg-gray-800 rounded-lg
                  shadow-lg overflow-hidden ring-1 ring-gray-700/50">
                  <button
                    onClick={() => {
                      navigate('/profile');
                      setShowProfileMenu(false);
                    }}
                    className="flex items-center w-full px-4 py-2 text-gray-400
                      hover:bg-gray-700/50 hover:text-white transition-colors duration-200"
                  >
                    <User size={16} className="mr-2" />
                    <span className="text-sm">Mi Perfil</span>
                  </button>
                  <button
                    onClick={handleLogoutClick}
                    className="flex items-center w-full px-4 py-2 text-red-400
                      hover:bg-red-500/10 hover:text-red-300 transition-colors duration-200"
                  >
                    <LogOut size={16} className="mr-2" />
                    <span className="text-sm">Cerrar Sesión</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};

// PropTypes
Sidebar.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  onExpandedChange: PropTypes.func.isRequired
};

export default Sidebar;